













































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { required, minLength } from 'vuelidate/lib/validators';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import {NotificationType, NotificationTypes, NotificationTypesforSurvey } from '@/entities/Notification/NotificationTypes';
import NotificationEnum from '@/enums/NotificationEnum';

@Component({
  validations: {
    localNotificationItem: {
      Title: {
        required,
        minLength: minLength(1),
      },
    },
  },
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },
  components: {
    loading,
    ModalPopup,
    ValidationSummary,
  },
})
export default class NotificationHeaderPane extends Vue {
  @Prop() private selectedNotificationItem?: NotificationItem;

  private notificationTypes: any = null;

  // constants
  private isMobileView = false;
  private showImageProcess = false;
  private readonly NotificationDialogueDeleted = 'NotificationDialogueDeleted';
  private localNotificationItem: NotificationItem | null = null;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private featureMissingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private EnableRankingDialogue = false;
  private EnableScalingDialogue = false;
  private readonly WarningMessage = 'WarningMessage';

  // Model Popup
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private showSectionModalPopup = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';

  // local variables
  private IsImageVisible = false;
  private description = '';
  private showFeatueredImage = true;
  private selectedNotificationTypeDescription = '';

  private showLoading = false;
  private loaderBorderColor = '';
  private localTypeId = -1;

  private mounted() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem,
    );
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (this.localNotificationItem.ImageFullUrl === '' || this.localNotificationItem.ImageFullUrl === null) {
      this.IsImageVisible = false;
    } else {
      this.IsImageVisible = true;
    }
    this.$root.$on(
      'notification-Header',
      (notificationItem: NotificationItem) => {
        if (notificationItem.IsSentPublish) {
          const element: HTMLBodyElement = document.getElementById('sendPushOnPublish') as HTMLBodyElement;
          element.setAttribute('checked', 'checked');
        }
      },
    );
    this.$root.$on(
      'notification-title-field-reset', () => {
        const element: HTMLBodyElement = document.getElementById('notificationTitle') as HTMLBodyElement;
        element.classList.remove('error');
        element.classList.remove('dirty');
        element.focus();
      },
    );
    if (this.localNotificationItem.Id === 0 || this.localNotificationItem.Id < 0) {
      const element: HTMLBodyElement = document.getElementById('notificationTitle') as HTMLBodyElement;
      if (element !== null) {
        element.focus();
      }
    }

    this.$root.$on(
      'setIsSentPublishProperties', (val: boolean) => {
        this.localNotificationItem!.IsSentPublish = val;
      },
    );
  }
  private created() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem,
    );
    this.GetRequestInfoFromStore();
    // setting values in drop down while creating a dialogue
    if (!this.localNotificationItem.IsSurvey) {
      this.notificationTypes = new NotificationTypes(
        this.$root,
        this.$axios,
        this.$store,
        this.$t,
      );
    } else {
    // setting values in drop down while creating a dialogue item under survey
      this.notificationTypes = new NotificationTypesforSurvey(
        this.$root,
        this.$axios,
        this.$store,
        this.$t,
      );
    }
    /* If Ranking type question is disabled at App setting level then we will remove the ranking type
  dialogue from notificationTypes array thus preventing the ranking type option to ever render in the
  add sub item in survey DROP DOWN*/
    if (!this.EnableRankingDialogue) {
      const index = this.notificationTypes.arrNotificationOptions.findIndex(
        (x: NotificationType) => x.Id === NotificationEnum.RankOptionItems);
      if (index > -1) {
        this.notificationTypes.arrNotificationOptions.splice(index, 1);
      }
    }
    /* If scaling type question is disabled at App setting level then we will remove the scale type
  dialogue from notificationTypes array thus preventing the scale type option to ever render in the
  add sub item in survey DROP DOWN*/
    if (!this.EnableScalingDialogue) {
      const index = this.notificationTypes.arrNotificationOptions.findIndex(
        (x: NotificationType) => x.Id === NotificationEnum.ScaleStatements);
      if (index > -1) {
        this.notificationTypes.arrNotificationOptions.splice(index, 1);
      }
    }
    if (!this.localNotificationItem.IsSurvey) {
      // fetching drop down strings for dialogue dropdown IN SURVEY in add item panel
      if (this.localNotificationItem.NotificationTypeId === NotificationEnum.RankOptionItems) {
        /* As per old admin the string to be displayed in drop down of dialogue type for Ranking
    IN SURVEY is 'Rank option items' so altering text for that*/
        this.selectedNotificationTypeDescription = this.$t('Dialogue.RankOptionItems').toString();
      } else if ( this.localNotificationItem.NotificationTypeId === NotificationEnum.ScaleStatements ) {
        /* As per old admin the string to be displayed in drop down of dialogue type for scaling
    IN SURVEY is 'Scale statements' so altering text for that*/
        this.selectedNotificationTypeDescription = this.$t('Dialogue.ScaleStatements').toString();
      } else {
        this.selectedNotificationTypeDescription = this.localNotificationItem.NotificationTypeDescription;
      }
    } else {
    // fetching drop down strings for dialogue dropdown in create panel

      const objIndex = this.notificationTypes.arrNotificationOptions!.findIndex(
        (obj: NotificationType) => obj.Id === this.localNotificationItem!.NotificationTypeId,
      );
      if (objIndex > -1) {
        this.selectedNotificationTypeDescription = this.notificationTypes.arrNotificationOptions[objIndex].Name;
      }
    }
  }
  private sendPushonPublishing() {
  // handles event of click on 'Send push on publish' span text
    this.localNotificationItem!.IsSentPublish = !this.localNotificationItem!.IsSentPublish;
    this.change();
  }
  private change() {
  // any property change
    this.$emit('setHeaderProperties', this.localNotificationItem);
    if (this.localNotificationItem!.Id === 0) {
      this.$root.$emit('notification-title-preserve', this.localNotificationItem!.Title);
    }
  }
  // On change of Question type
  private OnNotificationtypeChange(event: any) {
    const typeId  = Number(event.target.value);
    this.localTypeId = typeId;
    if (this.localNotificationItem!.CopiedNotificationId === 0) {
      this.CreateNewFromDropDown();
    } else {
      this.showSectionModalPopup = this.WarningMessage;
      this.modalPopupVisible = true;
      this.modalPopupContent = this.GetMoveToAnotherItemMessage();
      this.SetModalPopupDefaultButtonText();
      this.showCloseButton = true;
    }
  }
  private clickOnImage(val: boolean) {
    if (!this.localNotificationItem!.IsSurvey) {
      this.$root.$emit('notification-include-image-file-upload', val);
    } else {
      this.$root.$emit('notification-include-in-survey-item-file-upload', val);
    }
  }
  private GetImageUrl() {
    return this.localNotificationItem!.ImageFullUrl === '' || this.localNotificationItem!.ImageFullUrl === null
      ? this.featureMissingImage
      : this.localNotificationItem!.ImageFullUrl;
  }
  // Assign description returned from Editor component
  private SetDescriptionValue(description: string) {
    if (description != null && this.localNotificationItem != null) {
      if (typeof description !== 'undefined') {
        this.localNotificationItem.Description = description;
        this.change();
      }
    }
  }
  private updateInformationInLocalNotificationItem() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem,
    );

    if (this.localNotificationItem.ImageFullUrl === '' || this.localNotificationItem.ImageFullUrl === null) {
      this.IsImageVisible = false;
    } else {
      this.IsImageVisible = true;
    }
  }
  private GetRequestInfoFromStore() {
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    this.EnableRankingDialogue = Boolean(reqInfo.EnableRankingDialogue);
    this.EnableScalingDialogue = Boolean(reqInfo.EnableScalingDialogue);
  }
  private GetMoveToAnotherItemMessage(): string {
    return this.$t('ChangeScreenMessage').toString();
  }
  private SetModalPopupDefaultButtonText() {
    this.modalPopupShowCancelButton = true;
    this.modalPopupConfirmButtonText = this.$t('YesButton').toString();
    this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      if (this.showSectionModalPopup === this.WarningMessage) {
        this.CreateNewFromDropDown();
      }
    } else {
      const element: any = document.getElementById('selNotificationtype') as any;
      if (element !== null) {
        element.value = this.localNotificationItem!.NotificationTypeId;
      }
    }
  }
  private CreateNewFromDropDown() {
    if (!this.localNotificationItem!.IsNewSurveyItem) {
      this.$root.$emit('notification-create-dialogue-from-dropdown', this.localTypeId);
    } else {
      this.$root.$emit('notification-create-dialogue-item-in-survey', this.localTypeId);
    }
  }

  @Watch('selectedNotificationItem')
  private onNotificationItemPropertyChange() {
    this.updateInformationInLocalNotificationItem();
  }
  @Watch('IsImageVisible')
  private IsIncludedImage(val: boolean) {
    this.$root.$emit('notification-is-image-included', val);
  }
}

