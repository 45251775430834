




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { required, minLength } from 'vuelidate/lib/validators';
import loading from '@/components/Common/loading.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { NotificationFolderSignalRModel } from '@/signalRRequest/Notification/NotificationFolderSignalRModel';
@Component({
  validations: {
    localFolderItem: {
      required,
      minLength: minLength(0),
    },
  },
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },
  components: {
    loading,
  },
})
export default class NotificationFolderEditItemPane extends Vue {
  public showLoading = false;
  public loaderBorderColor?: string = '';
  @Prop() private selectedFolderItem?: string;
  @Prop() private applicationborderColor?: string;

  private localFolderItem = '';

  private mounted() {
    this.localFolderItem = JSON.parse(JSON.stringify(this.selectedFolderItem!));
  }
  private Cancel() {
    this.$v.$reset();
    this.$emit('notification-folder-item-cancel-operation', this.selectedFolderItem);
  }
  private GetPlaceholder(): string {
    return this.$t('Folder.Placeholder').toString();
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      this.$root.$emit('show-notification-popup', true);
      this.$root.$emit('show-notification-loading', true);
    }, 100);
    const signalR: NotificationFolderSignalRModel = new NotificationFolderSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    signalObject = signalR.SingleUpdateFolderItemSignalRModelRequest;
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
    }).toString();

    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({
      selectedName: this.localFolderItem, // Name to de dispalyed in Notification Popup
    });
    signalR.AddToNotificationPopup(signalObject);
    return signalObject;
  }
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
  ) {
    const signalR: NotificationFolderSignalRModel = new NotificationFolderSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    signalObject.SelectedIds = [0];
    signalObject.JSONData = JSON.stringify(
      {
        updatedName: this.localFolderItem,
        oldName: this.selectedFolderItem,
        selectedName: this.localFolderItem, // Name to de dispalyed in Notification Popup
      });
    signalR.PerformFolderSingleOperation(signalObject);
  }
  private Submit() {
    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }
    this.$v.$reset();
    // No errors submitting.
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    const signalObject = this.SendNotificationRequest();
    this.$axios
      .post(
        '/Notification/UpdateNotificationGroup', {
          oldGroupName : this.selectedFolderItem,
          newGroupName : this.localFolderItem,
        })
      .then(() => {
        // Successful update / save
        this.$emit(
          'notification-folder-item-edit-update-operation',  // for closing Edit pane
          this.localFolderItem,
        );
        this.SendNotificationRequestToQueue(
          signalObject,
        );

      })
      .catch(() => {
        // Log error if required
      });
  }
}
