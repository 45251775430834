/* eslint-disable max-len */
import { BaseSignalRModel } from '../BaseSignalRModel';
import { AxiosInstance } from 'axios';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { SignalRModelRequest } from '../SignalRModelRequest';
import { Vue } from 'vue-property-decorator';
import SubModuleEnum from '@/enums/SubModuleEnum';
import ModuleEnum from '@/enums/ModuleEnum';
import { NotificationFolder, NotificationFolderItemSignalR } from '@/entities/Notification/NotificationFolder';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';

const NotificationFolderSignalRModelRequestIds = {
  SingleEdit: 1,
  SingleDelete: 2,
};

export class NotificationFolderSignalRModel extends BaseSignalRModel {
  // Get SignalRModelRequest for Updating a single folder item
  get SingleUpdateFolderItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationFolderSignalRModelRequestIds.SingleEdit);
  }
  // Get SignalRModelRequest for Deleting a single folder item
  get SingleDeleteFolderItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationFolderSignalRModelRequestIds.SingleDelete);
  }
  readonly notifcationBatchOperationURL = '/BulkOperations/PerformBatchOperation';
  readonly notifcationPostToClientsSignalRURL = '/BulkOperations/PostToClientsSignalR';
  constructor(public rootObj: any, public axios: AxiosInstance, public $store: any, public $t: any) {
    super(rootObj, axios, $store);
    const reqInfo: AppRequestInfo = this.GetRequestInfoFromStore();
    const loggedInUserFullName = reqInfo.FirstName + ' ' + reqInfo.LastName;
    const signalRAPICustomCategoryURL = Vue.prototype.$SignalRBaseURL + 'api/SignalRInformation/PushCustomCategorySignalRChanges';

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationFolderSignalRModelRequestIds.SingleEdit, ModuleEnum.DialogueFolder, SubModuleEnum.SingleEdit,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, BaseSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType,signalRAPICustomCategoryURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingFolderItem', 'Notifications.UpdatedFolderItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleNotificationFolder'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationFolderSignalRModelRequestIds.SingleDelete, ModuleEnum.DialogueFolder, SubModuleEnum.SingleDelete,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, BaseSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPICustomCategoryURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteSingleFolderItem', 'Notifications.DeletedSingleFolderItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleNotificationFolder'));
  }
  // Add to Notification Popup that an operation has started
  public AddToNotificationPopup(signalRModelRequest: SignalRModelRequest) {
    this.EmitAddNewNotifications(signalRModelRequest);
  }
  // Get Folder Item send to SignalR as JSON data
  public GetFolderItemSendToSignalR(notificationFolder: NotificationFolder): string {
    const item: NotificationFolderItemSignalR =
        NotificationFolderItemSignalR.GetNotificationFoldersItemSignalR(notificationFolder);
    return JSON.stringify(item);
  }
  // Perform Folder Single Operations
  public PerformFolderSingleOperation(signalRModelRequest: SignalRModelRequest) {

    this.SendSignalRModelRequestToHubWithoutAddingToQueue(signalRModelRequest, this.notifcationPostToClientsSignalRURL);
    this.SendSignalRModelRequestToQueueWithoutEmit(signalRModelRequest, this.notifcationBatchOperationURL);
  }
  // Method that fires when Folder SignalR response model is received from Hub
  public NotificationFolderSignalRModelResponseReceived(signalRModelRequest: SignalRModelRequest) {
    this.SignalRModelResponseReceived(signalRModelRequest);
  }
}
