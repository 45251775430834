






























































































































































import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import ModuleEnum from '@/enums/ModuleEnum';
import { StoreHelper } from '@/store/StoreHelper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({})

export default class LinkNotificationWithEntity  extends Vue {
  @Prop() private LinkedInformation?: CustomCategoryItem[];
  @Prop() private SelectedEntityId?: number;
  @Prop() private LinkedPrograms?: ProgramItem[];

  private isItemLinkedWithDialogue = false;
  private isDialogueLinkedWithinformation = false;
  private localLinkedInformation?: CustomCategoryItem = CustomCategoryItem.createEmpty();
  private localLinkedProgram?: ProgramItem = ProgramItem.createEmpty();
  private localAllInformations?: CustomCategoryItem[] = [];
  private localAllPrograms?: ProgramItem[] = [];
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private linkedItem: string = require('@/assets/Images/link_icon_small.svg');
  private heading = '';
  private description = '';
  private enableProgram = false;
  private enableInformation = false;
  private isLinkedItemTooltipInfoVisible = false;

  private mounted() {
    this.$root.$emit('notification-is-linked-an-item', false);
    if(this.LinkedPrograms !== null && this.LinkedPrograms !== undefined) {
      this.AssignProgramDataInLocalObject(this.LinkedPrograms);
    }
    if(this.LinkedInformation !== null && this.LinkedInformation !== undefined) {
      this.AssignDataInLocalObject(this.LinkedInformation);
    }
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    this.enableProgram = storeHelper.GetEnableProgramFromStore();
    this.enableInformation  = storeHelper.GetEnableInformationFromStore();
    this.GetHeading();
    this.$root.$on(
      'linkedInformationData',
      (customCategory: CustomCategoryItem[]) => {
        this.AssignDataInLocalObject(customCategory);
        this.isDialogueLinkedWithinformation = true;
      },
    );
    this.$root.$on(
      'linked-program-data',
      (program: ProgramItem[]) => {
        this.AssignProgramDataInLocalObject(program);
        this.isDialogueLinkedWithinformation =false;
      },
    );
    this.$root.$on('clearInformations', () => {
      this.localLinkedInformation = CustomCategoryItem.createEmpty();
      this.localAllInformations = [];
    });
    this.$root.$on('update-notification-customecategory-item', (data: CustomCategoryItem[]) => {
      this.AssignDataInLocalObject(data);
    });
    this.$root.$on('update-notification-program-item', (data: ProgramItem[]) => {
      this.AssignProgramDataInLocalObject(data);
    });
  }

  private BtnClickAddEntity(val: any){
    if (val === ModuleEnum.Program) {
      this.$emit('clickAddProgram', ModuleEnum.Program);
    } else{
      this.$emit('clickAddInformation', ModuleEnum.Information);
    }
  }

  private GetHeading(){
    if (this.enableProgram && this.enableInformation) {
      this.heading =  this.$t('Notification.LinkedEntity.Link').toString() + this.$t('Notification.LinkedEntity.AProgram').toString()+ this.$t('Or').toString() + this.$t('Notification.LinkedEntity.AnInformation').toString();
      this.description =this.$t('Notification.LinkedEntity.LinkAnItemDescription1').toString() + this.$t('Notification.LinkedEntity.AProgram').toString()+ this.$t('Or').toString() + this.$t('Notification.LinkedEntity.AnInformation').toString() + this.$t('Notification.LinkedEntity.LinkAnItemDescription2').toString();
    } else if(!this.enableProgram && this.enableInformation){
      this.isDialogueLinkedWithinformation = true;
      this.heading =  this.$t('Notification.LinkedEntity.Link').toString() + this.$t('Notification.LinkedEntity.AnInformation').toString();
      this.description =this.$t('Notification.LinkedEntity.LinkAnItemDescription1').toString()  + this.$t('Notification.LinkedEntity.AnInformation').toString() + this.$t('Notification.LinkedEntity.LinkAnItemDescription2').toString();
    }  else if(this.enableProgram && !this.enableInformation){
      this.heading =  this.$t('Notification.LinkedEntity.Link').toString() + this.$t('Notification.LinkedEntity.AProgram').toString();
      this.description =this.$t('Notification.LinkedEntity.LinkAnItemDescription1').toString() + this.$t('Notification.LinkedEntity.AProgram').toString()+ this.$t('Notification.LinkedEntity.LinkAnItemDescription2').toString();
    }
  }

  private GetImageUrl(item: any) {
    return item === null ? this.missingImage : item;
  }
  private AssignDataInLocalObject(data: CustomCategoryItem[]){
    if (data !== null) {
      this.localLinkedInformation = CustomCategoryItem.createEmpty();
      this.localAllInformations = [];
      data.forEach((element) => {
        if (element.IsLinked) {
          this.localLinkedInformation = element;
          this.isItemLinkedWithDialogue = true;
          this.isDialogueLinkedWithinformation = true;
        }
        this.localAllInformations!.push(element);
      });
    }
  }

  private AssignProgramDataInLocalObject(data: ProgramItem[]){
    if (data !== null) {
      this.localLinkedProgram = ProgramItem.createEmpty();
      this.localAllPrograms = [];
      data.forEach((element) => {
        if (element.IsLinked) {
          this.localLinkedProgram = element;
          this.isItemLinkedWithDialogue = true;
          this.isDialogueLinkedWithinformation = false;
        }
        this.localAllPrograms!.push(element);
      });
    }
  }
  private IsLinkedInformationBtnVisible(){
    return (this.enableInformation && this.isItemLinkedWithDialogue && this.isDialogueLinkedWithinformation && (this.localLinkedInformation === null || this.localLinkedInformation!.CustomCategoryId === 0)) ? true : false;
  }

  private IsLinkedProgramBtnVisible(){
    return (this.enableProgram && this.isItemLinkedWithDialogue && !this.isDialogueLinkedWithinformation && (this.localLinkedProgram === null || this.localLinkedProgram!.Id === 0)) ? true : false;
  }
  private IsLinkedInformationVisible(){
    return (this.enableInformation && this.isItemLinkedWithDialogue && this.isDialogueLinkedWithinformation && this.localLinkedInformation !== null && this.localLinkedInformation!.CustomCategoryId > 0) ? true : false;
  }

  private IsLinkedProgramVisible(){
    return (this.enableProgram && this.isItemLinkedWithDialogue && !this.isDialogueLinkedWithinformation && this.localLinkedProgram !== null && this.localLinkedProgram!.Id > 0) ? true : false;
  }

  private SelectItemLinkedWithDialogue(){
    const el = document.querySelector('.div-linked-dialogue');
    if (el) {
      this.ScrollToBottom(el);
    }
  }
  private ScrollToBottom(el: Element) {
    setTimeout(() => {
      el.scrollTop = el.scrollHeight;
      el.scrollIntoView();
    }, 100);
  }
  private GetInformationString(val: boolean) {
    this.isLinkedItemTooltipInfoVisible = val;
  }
  @Watch('isItemLinkedWithDialogue')
  private IsItemLinkedWithDialogue(val: boolean) {
    this.$root.$emit('notification-is-linked-an-item', val);
  }
}
