








import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { CommonHighchartData } from '@/entities/Notification/CommonHighchartData';
import { Chart } from 'highcharts-vue';
import HighchartHelper from '@/helper/HighchartHelper';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';

@Component({
  components: {
    highcharts: Chart,
  },
})
export default class HighchartSpiderChart extends Vue {
  @Prop() chartData!: CommonHighchartData;
  private localChartData: CommonHighchartData = CommonHighchartData.createEmpty();
  private showCharts = false;
  private highchartData: object = {};
  private More = require('highcharts/highcharts-more');
  private mounted() {
    this.localChartData = Object.assign({},this.chartData);
    this.More(Highcharts);
    this.SetChartData();
    if(Object.keys(this.highchartData).length > 0) {
      this.showCharts = true;
    }
  }
  private SetChartData() {
    exporting(Highcharts);
    this.highchartData = {
      chart: {
        polar: true,
        type: 'line',
        marginBottom: HighchartHelper.ChartMargin,
        marginLeft: HighchartHelper.ChartMargin,
        backgroundColor: HighchartHelper.ChartBackgroundColor,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: this.localChartData.ChartCategories,
        tickmarkPlacement: 'on',
        lineWidth: HighchartHelper.lineWidth,
        labels: {
          useHTML: true,
          style: {
            fontWeight: this.localChartData.FontWeight,
            fontFamily: this.localChartData.FontFamily,
            fontSize: this.localChartData.FontSize,
            color: this.localChartData.FontColor,
          },
          formatter() {
            const self: any = this;
            const val = self.value;
            const tooltip = self.value;
            return '<span class="data-label" title = "' + tooltip + '">' + val + '</span>';
          },
        },
      },
      yAxis: {
        gridLineInterpolation: 'polygon',
        tickmarkPlacement: 'on',
        tickInterval: HighchartHelper.TickInterval,
        lineWidth: HighchartHelper.lineWidth,
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        outside: true,
        animation: false,
        useHTML: true,
        style: {
          fontFamily: HighchartHelper.FontFamily,
          fontWeight: this.localChartData.FontWeight,
        },
        backgroundColor: HighchartHelper.tooltipBackgroundColor,
        formatter(this: Highcharts.PointLabelObject) {
          return this.point.category +' = '+ this.point.y ;
        },
      },
      plotOptions: {
        area: {
          marker: {
            radius: 5,
          },
        },
      },
      exporting: HighchartHelper.Exporting,
      series: [{
        name: '',
        type: 'area',
        fillOpacity: HighchartHelper.SpiderFillOpacity,
        colorByPoint: true,
        colors: this.localChartData.Colors,
        showInLegend: false,
        data: this.localChartData.ChartData,
        pointPlacement: 'on',
        lineWidth: HighchartHelper.lineWidth,
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: this.localChartData.FontWeight,
            fontFamily: this.localChartData.FontFamily,
            fontSize: this.localChartData.FontSize,
            color: this.localChartData.FontColor,
            textOutline: 'none',
            textShadow: 'none',
            opacity: 1,
          },
        },
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            pane: {
              size: '60%',
            },
            xAxis: {
              labels: {
                y: -10,
              },
            },
          },
        }],
      },
    };
  }
  @Watch('chartData')
  private CheckChangeInChartData(newVal: CommonHighchartData) {
    this.localChartData = newVal;
    this.SetChartData();
  }
}
