




















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import i18n from '@/i18n';
import { PowerPointToken } from '@/entities/Notification/NotificationResults';
import moment from 'moment';
@Component({
  components: {
    VueModalPopUp,
  },
})
export default class GenerateToken extends Vue {
  @Prop() NotificationId?: number;
  @Prop() PowerPointToken?: PowerPointToken;

  private localPowerPointTokenToken = PowerPointToken.createEmpty();
  private greenTickIcon = require('@/assets/Images/green-tick.svg');
  private copyIcon = require('@/assets/Images/copy-icon.svg');
  private redCrossIcon = require('@/assets/Images/red-cross.svg');
  private visibleRevokeToken = false;
  private visibleTokenRevoked = false;
  private visibleTokenGenerated = false;
  private localNotificationItemId = 0;
  private showModalPopup = false;
  private revokeTokenModelPopup: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();
  private hideAcknowledgedMessageTime = 2000;
  private showKeyCopiedMessage = false;
  private expirationDate = '';
  private visibleTokenExtended = false;
  private visibleTokenExpired = false;
  private mounted(){
    if (this.NotificationId !== null && this.NotificationId !== undefined) {
      this.localNotificationItemId = this.NotificationId;
    }
    if (this.PowerPointToken !== null && this.PowerPointToken !== undefined) {
      this.AssignDataInLocalObject(this.PowerPointToken);
      this.visibleTokenGenerated = true;
      this.visibleRevokeToken = true;
      if ( moment(this.localPowerPointTokenToken.ExpirationDate.toUTCString()) < moment(new Date().toUTCString())){
        this.visibleTokenExpired = true;
      }
    }
  }

  private GenerateUniqueToken(id: number) {
    if(!this.visibleTokenRevoked) {
      this.$axios
        .post<any>('Notification/GenerateUniqueToken', {
        notificationId: id,
      })
        .then((response) => {
          {
            this.localPowerPointTokenToken.ExpirationDate = response.data.powerPointToken.ExpirationDate;
            this.expirationDate = this.FormatDate(this.localPowerPointTokenToken.ExpirationDate);
            this.localPowerPointTokenToken.Token = response.data.powerPointToken.Token;
            this.localPowerPointTokenToken.Id = response.data.powerPointToken.Id;
            this.visibleRevokeToken = true;
            setTimeout(() => {
              this.visibleTokenGenerated = true;
            }, this.hideAcknowledgedMessageTime);
            this.visibleTokenGenerated = false;
          }
        });
    }
  }
  private FormatDate(fullDate: Date) {
    return moment(fullDate).utc().format('MMM DD, YYYY');
  }
  private RevokeUniqueTokenAPI(id: number) {
    this.$axios
      .post<any>('Notification/RevokeToken', {
      notificationId: id,
    })
      .then((response) => {
        {
          if (response.data.Success === true) {
            this.localPowerPointTokenToken.ExpirationDate = new Date();
            this.localPowerPointTokenToken.Token = '';
            this.visibleRevokeToken = false;
            setTimeout(() => {
              this.visibleTokenRevoked = false;
            }, this.hideAcknowledgedMessageTime);
            this.visibleTokenRevoked = true;
          }
        }
      });
  }

  private ExtendUniqueTokenDate(id: number) {
    this.$axios
      .post<any>('Notification/ExtendUniqueToken', {
      tokenId: id,
    })
      .then((response) => {
        {
          if (response.data.Success === true) {
            setTimeout(() => {
              this.visibleTokenExtended = false;
            }, this.hideAcknowledgedMessageTime);
            this.visibleTokenExtended = true;
            this.localPowerPointTokenToken.ExpirationDate =  response.data.expirationDate;
            this.expirationDate = this.FormatDate(this.localPowerPointTokenToken.ExpirationDate);
          }
        }
      });
  }

  //  Close Model Popup
  private CloseModelPopup() {
    this.showModalPopup = false;
  }

  private SaveModelPopUp() {
    this.showModalPopup = false;
    this.RevokeUniqueTokenAPI(this.localNotificationItemId);
  }

  private RevokeUniqueToken() {
    this.showModalPopup = true;
    this.revokeTokenModelPopup = new VueModalPopUpViewModel(
      i18n.t('Notification.PowerPointIntegration.RevokeToken').toString(),
      i18n
        .t('Notification.PowerPointIntegration.RevokeTokenDescription')
        .toString(),
      false,
      '',
      i18n.t('Notification.PowerPointIntegration.DontRevokeToken').toString(),
      i18n.t('Notification.PowerPointIntegration.Revoke').toString(),
    );
  }
  private copyLinkToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.showKeyCopiedMessage = true;
      } else {
        alert('Oops, unable to copy');
      }
    } catch (err) {
      alert('Oops, unable to copy');
    }
    if (this.showKeyCopiedMessage) {
      setTimeout(() => {
        this.showKeyCopiedMessage = false;
      }, this.hideAcknowledgedMessageTime);
    }
    document.body.removeChild(selBox);
  }

  private AssignDataInLocalObject(val: PowerPointToken){
    this.localPowerPointTokenToken.Id = val.Id;
    this.localPowerPointTokenToken.Token = val.Token;
    this.localPowerPointTokenToken.ExpirationDate = val.ExpirationDate;
    this.expirationDate = this.FormatDate(this.localPowerPointTokenToken.ExpirationDate);
  }

  @Watch('PowerPointToken')
  private OnChange(val: PowerPointToken) {
    if (val !== null && val !== undefined) {
      this.AssignDataInLocalObject(val);
      if (this.localPowerPointTokenToken.Id !== null && this.localPowerPointTokenToken.Id !== undefined) {
        this.visibleTokenGenerated = true;
        this.visibleRevokeToken = true;
        if ( moment(this.localPowerPointTokenToken.ExpirationDate.toUTCString()) < moment(new Date().toUTCString())){
          this.visibleTokenExpired = true;
        }
      }
    }
  }
}

