












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NotificationSelectionTypeModel } from '@/entities/Notification/NotificationSelectionTypeModel';
import ChartTypeEnum from '@/enums/ChartTypeEnum';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import NotificationEnum from '@/enums/NotificationEnum';
@Component({
  components: {
  },
})
export default class NotificationSelectionType extends Vue {
  @Prop() selectedNotificationItem!: NotificationItem;
  @Prop() selectChartType!: NotificationSelectionTypeModel[];
  private chartListModel: NotificationSelectionTypeModel[] = [];
  private localNotificationItem: NotificationItem = NotificationItem.createEmpty();
  private greenTickIcon = require('@/assets/Images/green-tick.svg');
  private totalChartOptions = 0; // current number of options available for charts
  private minimumOptionsForSpiderChart = 2; // used for converting chart type to slider when number of chart options are less than or equal to 2 in case of scale statements
  private showTooltip = false;
  private mounted() {
    this.localNotificationItem = Object.assign({},this.selectedNotificationItem);
    this.chartListModel = Object.assign({},this.selectChartType);
    this.$root.$on('total-options-count',(totalChartOptions: number)=> {
      this.totalChartOptions = totalChartOptions;
      if(Object.keys(this.chartListModel).length > 0 && this.chartListModel !== undefined) {
        if(this.localNotificationItem.NotificationTypeId === NotificationEnum.ScaleStatements) {
          this.ChangeSelectedChartType(ChartTypeEnum.SliderChart);
        }
      }
    });
  }
  // this helps in disabling the spider chart icon and calling the ShowTooltip function. This checks for the case when total number of options are less than or equal to 2 and selected chart type is spider chart
  private IsChartDisabled(id: number) {
    return this.totalChartOptions <= this.minimumOptionsForSpiderChart &&  id === ChartTypeEnum.SpiderChart;
  }
  private ChangeSelectedChartType(id: number) {
    if(!this.IsChartDisabled(id)) {
      this.localNotificationItem.SelectedChartTypeId = id;
      this.$emit('change-selected-chart-type-id',id);
    }
  }
  // this helps in showing tooltip when total number of options are less than or equal to 2 and notification type is scaling
  private ShowTooltip(val: boolean) {
    this.showTooltip = val;
  }
  @Watch('selectChartType')
  private CheckChangeInselectChartType(newval: NotificationSelectionTypeModel[]) {
    this.chartListModel = newval;
  }
  @Watch('selectedNotificationItem',{deep: true})
  private CheckChangeInselectedNotificationItem(newVal: NotificationItem) {
    this.localNotificationItem = newVal;
  }
}
