/* eslint-disable max-len */
import { BaseSignalRModel } from '../BaseSignalRModel';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import EmitEnum from '@/enums/EmitEnums';
import { SignalRModelRequest } from '../SignalRModelRequest';
import { Vue } from 'vue-property-decorator';
import { AxiosInstance } from 'axios';
import { NotificationItem, NotificationItemSignalR } from '@/entities/Notification/NotificationItem';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';

const NotificationSignalRModelRequestIds = {
  BulkDelete: 1,
  BulkExportDialogues: 2,
  SingleCreate: 3,
  SingleEdit: 4,
  SingleDelete: 5,
  SingleDetailEdit: 6,
  Sorting: 7,
  BulkAssignFolder: 8,
  BulkLinkProgram: 9,
  BulkDeleteResponses: 10,
};

export class NotificationSignalRModel extends BaseSignalRModel {
  // Get SignalRModelRequest for deleting seleted notification Items in a batch
  get DeleteSelectedNotificationSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationSignalRModelRequestIds.BulkDelete);
  }
  // Get SignalRModelRequest for adding a single notification item
  get SingleCreateNotificationItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationSignalRModelRequestIds.SingleCreate);
  }
  get SingleUpdateNotificationItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationSignalRModelRequestIds.SingleEdit);
  }
  get SortingNotificationItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationSignalRModelRequestIds.Sorting);
  }
  // Get SignalRModelRequest for clearing responses item
  get ClearResponsesSelectedNotificationItemsSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationSignalRModelRequestIds.BulkDeleteResponses);
  }
  // Get SignalRModelRequest for Deleting a single information item
  get SingleDeleteNotificationSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationSignalRModelRequestIds.SingleDelete);
  }
  get BulkAssignFolderSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationSignalRModelRequestIds.BulkAssignFolder);
  }
  get ExportSelectedNotificationSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationSignalRModelRequestIds.BulkExportDialogues);
  }
  readonly batchOperationURL = '/BulkOperations/PerformBatchOperation';
  readonly postToClientsSignalRURL = '/BulkOperations/PostToClientsSignalR';
  constructor(public rootObj: any, public axios: AxiosInstance, public $store: any, public $t: any) {
    super(rootObj, axios, $store);
    const reqInfo: AppRequestInfo = this.GetRequestInfoFromStore();
    const loggedInUserFullName = reqInfo.FirstName + ' ' + reqInfo.LastName;
    const signalRAPIDialogueURL = Vue.prototype.$SignalRBaseURL + 'api/SignalRDialogue/PushDialogueSignalRChanges';

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationSignalRModelRequestIds.BulkExportDialogues, ModuleEnum.Dialogue, SubModuleEnum.DialogueExportSelected,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, NotificationSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIDialogueURL , [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteDialogueItems', 'Notifications.DeletedDialogueItems', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionDeleteNotifications'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationSignalRModelRequestIds.BulkDelete, ModuleEnum.Dialogue, SubModuleEnum.Delete,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, NotificationSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIDialogueURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteSingleDialogueItem', 'Notifications.DeletedSingleDialogueItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionDeleteNotifications'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationSignalRModelRequestIds.BulkDeleteResponses, ModuleEnum.Dialogue, SubModuleEnum.DialogueDeleteAllResponse,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, NotificationSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIDialogueURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteDialogueResponseItems', 'Notifications.DeletedDialogueResponseItems', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionDeleteAllResponseInNotifications'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationSignalRModelRequestIds.BulkAssignFolder, ModuleEnum.Dialogue, SubModuleEnum.AssignToFolder,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, NotificationSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIDialogueURL, [], 'Notifications.EditOperation', '', 'Notifications.UpdateDialogueItemsFolder', 'Notifications.UpdatedDialogueItemsFolder', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdateNotificationGroupFolder'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationSignalRModelRequestIds.SingleDelete, ModuleEnum.Dialogue, SubModuleEnum.SingleDelete,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, NotificationSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIDialogueURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteSingleDialogueItem', 'Notifications.DeletedSingleDialogueItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleDialogue'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationSignalRModelRequestIds.SingleCreate, ModuleEnum.Dialogue, SubModuleEnum.SingleCreate,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, NotificationSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIDialogueURL, [], 'Notifications.SaveOperation', '', 'Notifications.SavingNotificationItem', 'Notifications.SavedDialogueItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleDialogue'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationSignalRModelRequestIds.SingleEdit, ModuleEnum.Dialogue, SubModuleEnum.SingleEdit,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, NotificationSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIDialogueURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingDialogueItem', 'Notifications.UpdatedDialogueItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleDialogue'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationSignalRModelRequestIds.Sorting, ModuleEnum.Dialogue, SubModuleEnum.Sorting, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, NotificationSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIDialogueURL, [], 'Notifications.UpdateOperation', '', 'Notifications.SortingDialogueList', 'Notifications.SortedDialogueList', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleDialogue'));

  }
  // Perform Notification Batch Operations
  public PerformNotificationBatchOperation(signalRModelRequest: SignalRModelRequest) {
    // disable Notification items
    if (signalRModelRequest.SelectedIds.length > 0) {
      this.DisableNotificationItems(signalRModelRequest);
    }
    this.SendSignalRModelRequestToHub(signalRModelRequest, this.batchOperationURL);
  }
  // Perform Program Single Operations
  public PerformNotificationSingleOperation(signalRModelRequest: SignalRModelRequest) {
    // disable program items
    if (signalRModelRequest.SelectedIds.length > 0) {
      if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate &&
                signalRModelRequest.SubModule !== SubModuleEnum.Sorting) {
        this.DisableNotificationItems(signalRModelRequest);
      }
    }
    this.SendSignalRModelRequestToHubWithoutAddingToQueue(signalRModelRequest, this.postToClientsSignalRURL);

    this.SendSignalRModelRequestToQueueWithoutEmit(signalRModelRequest, this.batchOperationURL);
  }
  // Get CustomCategory Item to send to SignalR as JSON data
  public GetNotificationItemToSendToSignalR(notificationItem: NotificationItem): string {

    const item: NotificationItemSignalR = NotificationItemSignalR.GetNotificationSignalRItem(notificationItem);
    return JSON.stringify(item);
  }
  // Method that fires when custom category SignalR response model is received from Hub
  public NotificationSignalRModelResponseReceived(signalRModelRequest: SignalRModelRequest) {
    this.SignalRModelResponseReceived(signalRModelRequest);
    // enable custom category items

    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate &&
            signalRModelRequest.SubModule !== SubModuleEnum.SingleDelete) {
      if (this.IsLoggedInUserIsMe(signalRModelRequest)) {
        this.EnableNotificationItems(signalRModelRequest);
      }
    }
  }
  public GetSelectedNotificationIdsToSendInJson(selectedItems: NotificationItem[]): number[] {

    const selectedNotificationIds: number[] = [];
    selectedItems.forEach((element) => {
      selectedNotificationIds.push(element.Id);
    });
    return selectedNotificationIds;
  }
  // Disable custom category Items and add to Notification Popup that an operation has started

  public DisableNotificationItemsAndAddToNotificationPopup(signalRModelRequest: SignalRModelRequest) {
    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate &&
            signalRModelRequest.SubModule !== SubModuleEnum.SingleDetailEdit &&
            signalRModelRequest.SubModule !== SubModuleEnum.Sorting) {
      this.DisableNotificationItems(signalRModelRequest);
    }
    this.EmitAddNewNotifications(signalRModelRequest);
  }
  // Update CustomCategory in array based on Index for data received from Signal R
  public FillNotificationObjectFromSignalRResponse(
    notificationItem: NotificationItemSignalR,
    objIndex: number,
    NotificationList: NotificationItem[],
  ): NotificationItem {
    const cItem = this.GetNotificationItem(NotificationList[objIndex], notificationItem);
    return cItem;
  }

  // Create new CustomCategory Item based on Response received from SignalR
  public FillNotificationObjectFromSignalRResponseNewItem(
    notificationItem: NotificationItemSignalR,
  ): NotificationItem {
    const cItem = this.GetNotificationItem(NotificationItem.createEmpty(), notificationItem);
    return cItem;
  }

  // Disable Selected Notification Items based on notification Ids
  private DisableNotificationItems(signalRModelRequest: SignalRModelRequest) {
    this.rootObj.$emit(EmitEnum.disableNotificationItems, signalRModelRequest.SelectedIds);
  }
  // Enable Selected Notification Items based on notification Ids
  private EnableNotificationItems(signalRModelRequest: SignalRModelRequest) {
    this.rootObj.$emit(EmitEnum.enableNotificationItems, signalRModelRequest.SelectedIds);
  }
  // Get Notification Item from NotificationR Item

  private GetNotificationItem(nItem: NotificationItem, notificationItem: NotificationItemSignalR): NotificationItem {
    nItem = NotificationItem.GetNotificationItemFromNotificationItemSignalR(nItem, notificationItem);
    return nItem;
  }
}
