











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { NotificationFolderSignalRModel } from '@/signalRRequest/Notification/NotificationFolderSignalRModel';
import { NotificationFolder } from '@/entities/Notification/NotificationFolder';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import NotificationFolderEditItemPane from '@/components/Notification/Folder/NotificationFolderEditItemPane.vue';
@Component({
  components: {
    NotificationFolderEditItemPane,
    loading,
    ModalPopup,
  },
})
export default class NotificationFolderListViewEditItemPane extends Vue {
  @Prop() private selectedEditFolderItem?: string;
  @Prop() private applicationborderColor?: string;
  private localFolderItem: string | null = null;
  private localFolderItemSignalR: NotificationFolder = NotificationFolder.createEmpty();
  private showEditFolder?: boolean = false;
  private loaderBorderColor = '';
  private showLoading = false;
  private isMobileView = false;
  private modalPopupVisible = false;
  private showCloseButton = true;
  private folderNameTobeDeleted = '';
  private modalPopupContent: string = this.GetRemoveFolderMessage();

  private mounted() {
    if (this.selectedEditFolderItem != null) {
      this.localFolderItem = this.selectedEditFolderItem;
      this.localFolderItemSignalR.Id = 0;
      this.localFolderItemSignalR.Name = this.selectedEditFolderItem;
      this.localFolderItemSignalR.IsSelected = false;
    }
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    // check for ipad and mobile view
    if (window.innerWidth <= 1300) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.$root.$on('notification-folder-close-others-operation', () => {
      this.showEditFolder = false;
    });

  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.Delete(this.folderNameTobeDeleted);
      this.$root.$emit('update-notification-folder-listing-dropdown', true);
    } else {
      return;
    }
  }
  // Remove Folder with modal popup
  private GetRemoveFolderMessage(): string {
    return this.$t('Folder.Dialogue.Delete.Message').toString();
  }
  private DeleteSelectedFolder(folder: string) {
    this.folderNameTobeDeleted = folder;
    this.modalPopupVisible = true;
  }
  private selectItem(val: string) {
    this.$root.$emit('notification-folder-close-others-operation');
    const selectedItem = val;
    this.localFolderItem = selectedItem;
    this.showEditFolder = true;
    this.$emit('notification-folder-item-edit-cancel-operation', selectedItem);
    // this.scrollToElement();
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      this.$root.$emit('show-notification-popup', true);
      this.$root.$emit('show-notification-loading', true);
    }, 100);
    const signalR: NotificationFolderSignalRModel = new NotificationFolderSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    signalObject = signalR.SingleDeleteFolderItemSignalRModelRequest;
    const arr: number[] = [];
    arr.push(this.localFolderItemSignalR.Id);
    signalObject.SelectedIds = arr;
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      selectedCount: arr.length, // Just single item delete
      user: signalR.GetUserName(
        signalObject,
        this.$t('UserName.You').toString(),
      ),
    }).toString();
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({selectedName: this.localFolderItemSignalR.Name});
    signalR.AddToNotificationPopup(signalObject);
    return signalObject;
  }
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    notificationFolderItem: NotificationFolder,
  ) {
    const signalR: NotificationFolderSignalRModel = new NotificationFolderSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(notificationFolderItem.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = JSON.stringify({selectedName: this.localFolderItemSignalR.Name});
    signalR.PerformFolderSingleOperation(signalObject);
  }
  private Delete(folderName: string) {
    const signalObject = this.SendNotificationRequest();
    this.$axios
      .post('/Notification/DeleteNotificationGroup', {
        oldGroupName: folderName,
      })
      .then((response) => {
        if (response.data.success === false) {
          // Server side validation failuere
          alert(this.$t('Message.UnableToDelete'));
        } else {
          // Successful delete
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localFolderItemSignalR,
          );
          // Successful delete
          // this.$root.$emit('notification-folder-item-delete-operation', folderName);
        }
      })
      .catch(() => {
        // Log error if required
      });
  }
  private GetImageUrl(): string {
    return 'https://meetapp.blob.core.windows.net/appl1/fa-folder-open_ffc21d_ffffff.png';
  }
  private getEditButtonstyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        width: 'auto',
      };
    } else {
      return {};
    }
  }
  private getLeftDivStyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        width: '48%',
      };
    } else {
      return { width: '60%' };
    }
  }
  private getRightDivStyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        width: '52%',
      };
    } else {
      return { width: '40%' };
    }
  }
  private getDeleteButtonstyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        'margin-left': '5px',
        'height': 'auto',
        'width': 'auto',
      };
    } else {
      return { 'margin-left': '15px' };
    }
  }
  // Scroll to particular element when come back from image process component
  private scrollToElement() {
    setTimeout(() => {
      const el = document.getElementById('customCategoryFolderItemEditPane');
      el!.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
        inline: 'nearest',
      });
    }, 100);
  }
  private NotificationFolderItemCancelOperation(pt: string) {
    this.localFolderItem = pt;
    this.showEditFolder = false;
  }
  private NotificationFolderItemEditedUpdatedOperation(pt: string) {
    this.localFolderItem = pt;
    this.showEditFolder = false;
  }
}

