


































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { required, minLength } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import { NotificationDimension } from '@/entities/Notification/NotificationDimension';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';

@Component({
  validations: {
    localNotificationOption: {
      Text: {
        required,
        minLength: minLength(1),
      },
    },
  },
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },
  components: {
    draggable,
    ValidationSummary,
  },
})
export default class NotificationDimensions extends Vue {
  @Prop() private notificationsDimensions?: NotificationDimension[];

  private optionAddCounter = 0;
  private maxOptionsAllowed = 10;
  private localDimensions: NotificationDimension[] = [];
  private dummyDimensions: NotificationDimension[] = [];
  private oldDim: NotificationDimension | null = null;

  private IsCreateNewLinkDisabled = false;
  private dragging = false;
  private isMobileView = false;
  private errors: string[] = [];
  private messages: string[] = [];

  // show Hide
  private midLevelDimensionsVisible = false;
  private noMidLevelDimension = true;
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }

  private mounted() {
    // check for ipad and mobile view
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (this.notificationsDimensions! !== undefined) {
      this.localDimensions = JSON.parse(JSON.stringify(this.notificationsDimensions));
      if (this.localDimensions.length === 2) {
        this.noMidLevelDimension = false;
      }
      this.midLevelDimensionsVisible = true;
      setTimeout(() => {
        this.ShowMidLabels();
      }, 100);
    }
    this.$root.$on(
      'add-new-dimension-for-create',
      () => {
        this.defaultDimensions();
        this.midLevelDimensionsVisible = false;
        setTimeout(() => {
          this.ShowMidLabels();
        }, 100);
      },
    );
  }
  private defaultDimensions() {
    const list: NotificationDimension[] = [
      {Id: -5, NotificationDimensionLabel: 'Strongly disagree ', NotificationDimensionValue : 1, NotificationId: 0},
      {Id: -4, NotificationDimensionLabel: '', NotificationDimensionValue : 2, NotificationId: 0},
      {Id: -3, NotificationDimensionLabel: '', NotificationDimensionValue : 3, NotificationId: 0 },
      {Id: -2, NotificationDimensionLabel: '', NotificationDimensionValue : 4, NotificationId: 0},
      {Id: -1, NotificationDimensionLabel: 'Strongly agree', NotificationDimensionValue : 5, NotificationId: 0}];
    this.localDimensions = list;
  }
  private ShowMidLabels() {
    this.midLevelDimensionsVisible = !this.midLevelDimensionsVisible;
    const element: HTMLBodyElement = document.getElementById('midLabelDiv') as HTMLBodyElement;
    element.hidden = this.midLevelDimensionsVisible;
  }
  private dimensionLabelChange(event: any) {
    const dimensionId = Number(event.target.id);
    const dimensionLabel = String(event.target.value);
    const objIndex = this.localDimensions.findIndex(
      (obj: NotificationDimension) => obj.Id === dimensionId,
    );
    this.localDimensions[objIndex].NotificationDimensionLabel = dimensionLabel;
  }
  private lowLabeldimensionValueChange(event: any) {
    let lowvalue = Number(event.target.value);
    lowvalue = lowvalue < 0 ? 0 : lowvalue;
    if (lowvalue > -1 && lowvalue <= 10) {
      this.dummyDimensions = JSON.parse(JSON.stringify(this.localDimensions));
      this.localDimensions = [];

      this.localDimensions.push({Id : this.dummyDimensions[0].Id, NotificationDimensionLabel: this.dummyDimensions[0].NotificationDimensionLabel, NotificationDimensionValue: Number(this.dummyDimensions[0].NotificationDimensionValue), NotificationId: 0});
      const highvalue = this.dummyDimensions[this.dummyDimensions.length - 1].NotificationDimensionValue;
      if (lowvalue > -1 && highvalue <= 10) {
        this.noMidLevelDimension = highvalue - lowvalue === 1 ? false : true;

        for ( let i = lowvalue + 1; i < highvalue ; i++) {
          this.dummyDimensions.forEach((value) => {
            if (value.NotificationDimensionValue === i) {
              this.oldDim = value;
            }
          });
          if (this.oldDim != null) {

            this.localDimensions.push({Id: this.oldDim.Id, NotificationDimensionLabel: this.oldDim.NotificationDimensionLabel, NotificationDimensionValue : i, NotificationId: 0});
          } else {

            this.localDimensions.push({Id: -Math.abs(9999 + i), NotificationDimensionLabel: '', NotificationDimensionValue : i, NotificationId: 0});
          }
          this.oldDim = null;
        }

        this.localDimensions.push({Id : this.dummyDimensions[this.dummyDimensions.length - 1].Id, NotificationDimensionLabel: this.dummyDimensions[this.dummyDimensions.length - 1].NotificationDimensionLabel, NotificationDimensionValue: Number(this.dummyDimensions[this.dummyDimensions.length - 1].NotificationDimensionValue), NotificationId: 0});
      }
    }
  }
  private highLabeldimensionValueChange(event: any) {
    const highvalue = Number(event.target.value);
    if ( highvalue <= 10) {
      this.dummyDimensions = JSON.parse(JSON.stringify(this.localDimensions));
      this.localDimensions = [];

      this.localDimensions.push({Id : this.dummyDimensions[0].Id, NotificationDimensionLabel: this.dummyDimensions[0].NotificationDimensionLabel, NotificationDimensionValue: Number(this.dummyDimensions[0].NotificationDimensionValue), NotificationId: 0});
      const lowvalue = this.dummyDimensions[0].NotificationDimensionValue;
      this.noMidLevelDimension = highvalue - lowvalue === 1 ? false : true;

      for ( let i = lowvalue + 1; i < highvalue ; i++) {
        this.dummyDimensions.forEach((value) => {
          if (value.NotificationDimensionValue === i) {
            this.oldDim = value;
          }
        });
        if (this.oldDim != null) {

          this.localDimensions.push({Id: this.oldDim.Id, NotificationDimensionLabel: this.oldDim.NotificationDimensionLabel, NotificationDimensionValue : i, NotificationId: 0});
        } else {

          this.localDimensions.push({Id: -Math.abs(8888 + i), NotificationDimensionLabel: '', NotificationDimensionValue : i, NotificationId: 0});
        }
        this.oldDim = null;
      }

      this.localDimensions.push({Id : this.dummyDimensions[this.dummyDimensions.length - 1].Id, NotificationDimensionLabel: this.dummyDimensions[this.dummyDimensions.length - 1].NotificationDimensionLabel, NotificationDimensionValue: Number(this.dummyDimensions[this.dummyDimensions.length - 1].NotificationDimensionValue), NotificationId: 0});
    }
  }
  // Clear Error List
  private ClearErrorsList() {
    this.errors = [];
  }

  // Clear Messages List
  private ClearMessagesList() {
    this.messages = [];
  }
  @Watch('notificationsDimensions')
  private onnotificationDeimnnsionsChanged(newval: NotificationDimension[]) {
    if (newval !== undefined) {
      this.localDimensions = JSON.parse(JSON.stringify(newval));
      this.noMidLevelDimension = newval.length === 2 ? false : true;
    }
  }
  @Watch('localDimensions', {deep : true})
  private onItemDimensionPropertyChange(newval: NotificationDimension[]) {

    this.$root.$emit('dimensionValidation', newval[0].NotificationDimensionValue, newval[newval.length - 1].NotificationDimensionValue);
    this.$emit('setDimension', newval);
  }
}
