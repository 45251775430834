































































































































































































import { Component, Vue } from 'vue-property-decorator';
import loading from '@/components/Common/loading.vue';
import { NotificationViewModel } from '@/entities/Notification/NotificationViewModel';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { StoreHelper } from '@/store/StoreHelper';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { NotificationSignalRModel } from '@/signalRRequest/Notification/NotificationSignalRModel';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import NotificationExportPopup from '@/components/Notification/NotificationExport/NotificationExportPopup.vue';
import {
  NotificationItem,
  NotificationItemSignalR,
} from '@/entities/Notification/NotificationItem';
import { NotificationSegmentQuestion } from '@/entities/Notification/NotificationSegmentQuestion';
import NotificationListView from '@/components/Notification/NotificationDetail/NotificationListView.vue';
import NotificationParentEditPane from '@/components/Notification/NotificationDetail/NotificationParentEditPane.vue';
import NotificationFolderListView from '@/components/Notification/Folder/NotificationFolderListview.vue';
import NotificationBatchEditPane from '@/components/Notification/BatchOperations/NotificationBatchEditPane.vue';
import NotificationAddItemPane from '@/components/Notification/AddNotification/NotificationAddItemPane.vue';
import { NotificationFolderSignalRModel } from '@/signalRRequest/Notification/NotificationFolderSignalRModel';
import NotificationEnum from '@/enums/NotificationEnum';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { ProgramItem } from '@/entities/Program/ProgramItem';

@Component({
  components: {
    loading,
    ModalPopup,
    NotificationExportPopup,
    NotificationListView,
    NotificationFolderListView,
    NotificationParentEditPane,
    NotificationBatchEditPane,
    NotificationAddItemPane,
  },
})
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export default class Notification extends Vue {
  // readonly properties
  private readonly All: string = 'All';
  private readonly folders = 'folders';
  private readonly CreateNewNotification = 'CreateNewNotification';
  private readonly CreateNewSurvey = 'CreateNewSurvey';
  private readonly selectedNotification = 'selectedNotification';
  private readonly SelectedBatchNotification = 'selectedBatchNotification';
  private readonly SelectedCreateNewDialogue = 'selectedCreateNewDialogue';
  private readonly NotificationUpdated = 'NotificationUpdated';
  private readonly NotificationDialogueBulkUpdated =
    'NotificationDialogueBulkUpdated';
  private readonly DialogueInspirationUrl = 'StaticContent/InspirationArea/admin-user-dialogue-inspiration.html';
  // properties
  private classicMode = false;
  private showLoading = false;
  private loaderBorderColor = '';
  private viewModel?: NotificationViewModel | null = null;
  private notificationFolders?: string[] = [];
  private TopLevel: string = this.$t('Dialogue.TopLevel').toString();
  private selectedNotificationItems: NotificationItem[] = [];
  private segmentQuestions: NotificationSegmentQuestion[] = [];
  private notificationItems: NotificationItem[] = [];
  private notificationEditPaneValues: any = null;
  private activeSubComponent = '';
  private selectedFolderItem: string | null = null;
  private selectedFolderItemInListing: string | null = null;
  private selectAllNotificationItems = false;
  private isMobileView = false;
  private isTabletView = false;
  private applicationId = 0;
  private applicationInstanceId = 0;
  private EnableRankingDialogue = false;
  private EnableScalingDialogue = false;
  private localEditedNotificationItemItem: any = null;
  private selectedNotificationItemSignalR: NotificationItemSignalR = NotificationItemSignalR.createEmpty();
  private notificationSignalRModelRequest: SignalRModelRequest = new SignalRModelRequest();
  private createNewClicked = false;
  private createNewFromDropDown = 'dropDown';
  private fileDownload = require('js-file-download');
  private exportFileName = '';
  private IsSurveyItemDirty = false;
  // Model Popup
  private modalPopupVisible = false;
  private showSection_ModalPopup = '';
  private showCloseButton = true;
  private modalPopupContent = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private localCustomCategoryFolders: CustomCategoryFolder[] = [];
  private localAllInformations: CustomCategoryItem[] = [];
  private localAllPrograms: ProgramItem[] = [];

  // Export popup
  private exportPopupVisible = false;

  private mounted() {
    this.showLoading = true;
    this.GetRequestInfoFromStore();
    if (this.$route.params.applicationborderColor != null) {
      this.loaderBorderColor = this.$route.params.applicationborderColor;
    }
    // check for mobile view
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (window.innerWidth <= 1024) {
      this.isTabletView = true;
    } else {
      this.isTabletView = false;
    }
    this.$axios
      .post<NotificationViewModel>('/Notification/GetNotificationsViewModel')
      .then((response) => {
        const copyViewModel = response.data;
        this.viewModel = copyViewModel;
        this.showLoading = false;
        this.localCustomCategoryFolders = this.viewModel.CustomCategoryFolders;
        this.localAllInformations = this.viewModel.CustomCategoryItems;
        this.localAllPrograms = this.viewModel.ProgramItems;
      })
      .catch(() => {
        this.showLoading = false;
      });
    this.$root.$on('notifications-uncheck-select-all', () => {
      this.selectAllNotificationItems = false;
    });
    this.$root.$on(
      'notification-item-edit-operation-with-notification',
      (notification: SignalRModelRequest) => {
        this.NotificationItemEditedOperationWithNotification(notification);
      },
    );
    this.$root.$on('reload-notification-data', () => {
      this.ReloadNotificationsData(false);
    });
    this.$root.$on('notification-new-folder-added', (folderName: string) => {
      this.NotificationFolderAdded(folderName);
    });
    this.$root.$on(
      'notification-folder-item-delete-operation',
      (folderName: string) => {
        this.NotificationFolderItemDeletedOperation(folderName);
      });
    this.$root.$on(
      'notification-viewmodel-folder-list-update-after-bulk-operation',
      (notificationUpdatedFolder: string[]) => {
        this.NotificationFoldersListReload(notificationUpdatedFolder);
      });
    this.$root.$on('notification-export-item-selected', (object: any) => {
      this.ConfirmExportClick(object);
    });
    this.$root.$on('notification-create-new-pane-show', () => {
      this.selectedNotificationItems = [];
      this.btnCreateNewNotificationItemClick();
    });
    this.$root.$on(
      'notification-create-new-operation',
      (notificationItem: NotificationItem, isCreatedFromDropDown: boolean) => {
        this.NotificationCreated(notificationItem, isCreatedFromDropDown);
      });
    this.$root.$on('notification-survey-sub-item-dirty', (val: boolean) => {
      this.IsSurveyItemDirty = val;
    });
    this.$root.$on('selected-folder-in-listing', (selectedFolder: string) => {
      if (selectedFolder === this.$t('Dialogue.All').toString()) {
        this.selectedFolderItemInListing = '';
      } else if (selectedFolder === this.$t('Dialogue.TopLevel').toString()) {
        this.selectedFolderItemInListing = '';
      } else {
        this.selectedFolderItemInListing = selectedFolder;
      }
    });
    this.$root.$on('notification-item-copied', (val: NotificationItem) => {
      this.CopyNotificationItemClick(val);
    });
    this.$root.$on(
      'selected-notification-folders-item-notification-completed',
      (notification: SignalRModelRequest) => {
        if (notification.IsSuccess) {
          this.PerformFolderOperationOnList(notification);
        }

        const signalR: NotificationFolderSignalRModel = new NotificationFolderSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.NotificationFolderSignalRModelResponseReceived(notification);
      });
    this.$root.$on(
      'set-notification-landing-page',
      (showLandingPage: boolean) => {
        if (showLandingPage) {
          this.createNewClicked = false;
          this.SetNotificationLandingPage();
        }
      });
    this.ListenSignalRResponseEvents();
  }
  private GetIframeUrl(): string {
    return this.$EnvironmentBaseURL + this.DialogueInspirationUrl;
  }
  private ListenSignalRResponseEvents() {
    this.$root.$on(
      'batch-selected-notification-items-completed',
      (notification: SignalRModelRequest) => {
        const signalR: NotificationSignalRModel = new NotificationSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.NotificationSignalRModelResponseReceived(notification);
        if (notification.IsSuccess) {
          if (this.selectedNotificationItems.length === 1) {
            const objIndex = notification.SelectedIds.findIndex(
              (obj: number) => obj === this.selectedNotificationItems[0].Id,
            );
            if (objIndex > -1) {
              this.modalPopupVisible = true;
              this.showCloseButton = false;
              this.showSection_ModalPopup = this.NotificationDialogueBulkUpdated;
              this.modalPopupShowCancelButton = false;
              this.modalPopupConfirmButtonText = this.$t(
                'Button.Ok',
              ).toString();
              this.modalPopupCancelButtonText = this.$t(
                'CancelButton',
              ).toString();
              if (notification.SubModule === SubModuleEnum.Delete) {
                this.modalPopupContent = this.$t('Message.ItemDeletedBy', {
                  username: notification.UserName,
                }).toString();
              } else {
                this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
                  username: notification.UserName,
                }).toString();
              }
            }
          } else if (this.selectedNotificationItems.length > 1) {
            if (signalR.IsLoggedInUserIsMe(notification)) {
              this.modalPopupVisible = false;
            } else {
              this.modalPopupVisible = true;
              this.showCloseButton = true;
              this.showSection_ModalPopup = this.NotificationDialogueBulkUpdated;
              this.modalPopupShowCancelButton = false;
              this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
              this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
              this.modalPopupContent = this.$t('Message.MultipleItemsUpdatedBy',
                {username: notification.UserName}).toString();
            }
          } else {
            this.modalPopupVisible = false;
          }
        }
      },
    );
  }
  // Reload List of Notifications from API
  private ReloadNotificationsData(showLoading: boolean) {
    // check if need to show loader
    if (showLoading) {
      this.showLoading = true;
    }
    this.$axios
      .post<NotificationViewModel>('/Notification/GetNotificationsViewModel')
      .then((response) => {
        const copyViewModel = response.data;
        this.viewModel = copyViewModel;
        this.showLoading = false;
        this.localCustomCategoryFolders = this.viewModel.CustomCategoryFolders;
        this.localAllInformations = this.viewModel.CustomCategoryItems;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  // #region  Notification Add/Edit

  private NotificationItemEditedOperationWithNotification(
    notification: SignalRModelRequest,
  ) {
    const ni: NotificationItemSignalR = JSON.parse(notification.JSONData);
    const objIndex = this.viewModel!.NotificationList.findIndex(
      (obj: NotificationItem) => obj.Id === ni.Id,
    );
    const signalR: NotificationSignalRModel = new NotificationSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    if (objIndex === -1) {
      this.$root.$emit('notification-update-isting-without-closing-edit-pane', true);
      // Created new Notification
      const pItem = signalR.FillNotificationObjectFromSignalRResponseNewItem(ni);
      this.viewModel!.NotificationList.unshift(pItem);

      setTimeout(() => {
        // Refresh folder list after new item created
        this.UpdateFolderListInViewModel(this.viewModel!.NotificationList);
      }, 100);
    } else {
      // Updated
      this.$root.$emit('notification-updated-listing-by-other-admin', true, ni.Id);
      // Avoid override updated current program if edit pane is diffirent
      this.$root.$emit('notification-update-isting-without-closing-edit-pane', true);
      if (
        this.selectedNotificationItems.length === 1 &&
        this.selectedNotificationItems[0].Id === ni.Id
      ) {
        this.CheckIfUpdatedItemIsOpenInEditMode(
          notification,
          signalR,
          objIndex,
          ni,
        );
      } else {
        this.SetNotificationItemInList(objIndex, signalR, ni);
      }
    }
    if (signalR.IsLoggedInUserIsMe(notification)) {
      this.$root.$emit('notification-get-all-without-watch');
    }
  }
  // check if updated item is open in edit mode
  private CheckIfUpdatedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
    signalR: NotificationSignalRModel,
    objIndex: number,
    ni: NotificationItemSignalR,
  ) {
    if (signalR.IsLoggedInUserIsMe(notification)) {
      // check if change is made by current logged in user
      this.PerformSelectedItemUpdateOperation(
        this.SetNotificationItemInList(objIndex, signalR, ni),
      );
    } else {
      // show model popup if change is made by other user
      this.modalPopupVisible = true;
      this.showSection_ModalPopup = this.NotificationUpdated;
      this.modalPopupShowCancelButton = true;
      this.modalPopupConfirmButtonText = this.$t('Button.Reload').toString();
      this.modalPopupCancelButtonText = this.$t('Button.Ignore').toString();
      this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedNotificationItemSignalR = ni;
      this.notificationSignalRModelRequest = notification;
    }
  }
  // Update item in viewmodel listing
  private SetNotificationItemInList(
    objIndex: number,
    signalR: NotificationSignalRModel,
    ni: NotificationItemSignalR,
  ): NotificationItem {
    const nItem = signalR.FillNotificationObjectFromSignalRResponse(
      ni,
      objIndex,
      this.viewModel!.NotificationList,
    );
    Vue.set(this.viewModel!.NotificationList, objIndex, ni); // Update array w reactivity

    setTimeout(() => {
      this.UpdateFolderListInViewModel(this.viewModel!.NotificationList);
    }, 100);

    return nItem;
  }

  private PerformSelectedItemUpdateOperation(cItem: NotificationItem) {
    this.selectedNotificationItems = [cItem];
  }
  // To removed this program item from program list.
  private notificationItemDeletedOperation(pi: NotificationItem) {
    const objIndex = this.viewModel!.NotificationList.findIndex(
      (obj: NotificationItem) => obj.Id === pi.Id,
    );
    Vue.delete(this.viewModel!.NotificationList, objIndex); // Update array w reactivity
    this.selectedNotificationItems = [];
    // to show Inspiration area
    this.activeSubComponent = '';
    this.$root.$emit('clearNotificationCheckboxes', []);
  }
  // Reload notification item in edit mode when user click on reload button
  private ReloadConfirmedNotificationItem() {
    const ni: NotificationItemSignalR = this
      .selectedNotificationItemSignalR;
    const objIndex = this.viewModel!.NotificationList.findIndex(
      (obj: NotificationItem) => obj.Id === ni.Id,
    );
    const signalR: NotificationSignalRModel = new NotificationSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );

    this.PerformSelectedItemUpdateOperation(
      this.SetNotificationItemInList(objIndex, signalR, ni),
    );
    this.$root.$emit('reload-notification-detail', ni);
    this.ManageTopLevelFolder();
  }
  // #endregion Notification Add/Edit

  // #region folder opertions

  // handle signalR requests
  private PerformFolderOperationOnList(notification: SignalRModelRequest) {
    // Check if folder deleted
    if (
      notification.Module === ModuleEnum.DialogueFolder &&
      notification.SubModule === SubModuleEnum.SingleDelete
    ) {
      const folderName: string = JSON.parse(notification.JSONData).selectedName;
      if (notification.SelectedIds.length > 0) {
        if (
          this.selectedNotificationItems.length === 1 &&
          this.selectedNotificationItems[0].Folder != null
        ) {
          this.CheckIfDeletedFolderItemIsOpenInEditMode(notification);
        } else {
          this.NotificationFolderItemDeletedOperation(folderName);
        }
      }
      this.$root.$emit('notification-get-all-without-watch');
    } else if (
      notification.Module === ModuleEnum.DialogueFolder &&
      notification.SubModule === SubModuleEnum.SingleEdit
    ) { // Check if folder edited
      const folderSignalRItem: any = JSON.parse(notification.JSONData);
      const oldfolderName: string = folderSignalRItem.oldName;
      const newfolderName: string = folderSignalRItem.updatedName;
      this.NotificationFolderItemEditedOperation(oldfolderName, newfolderName);
      this.$root.$emit(
        'update-notification-folder-listing-dropdown-with-notification',
        notification,
        newfolderName,
      );
    }
  }
  private CheckIfDeletedFolderItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {

    const signalR: NotificationSignalRModel = new NotificationSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    const folderName: string = JSON.parse(notification.JSONData).selectedName;
    if (signalR.IsLoggedInUserIsMe(notification)) {
      this.NotificationFolderItemDeletedOperation(folderName);
    } else {
      this.NotificationFolderItemDeletedOperation(folderName);
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSection_ModalPopup = this.NotificationDialogueBulkUpdated;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupContent = this.$t('Message.FolderDeletedBy', {
        username: notification.UserName,
      }).toString();
    }
  }

  private NotificationFolderItemEditedOperationWithNotification(
    pt: string,
    notification: SignalRModelRequest,
  ) {
    const objIndex = this.viewModel!.Groups.findIndex(
      (obj: string) => obj === pt,
    );
    if (objIndex === -1) {
      // Created
      this.viewModel!.Groups.push(pt);
    } else {
      // Updated
      Vue.set(this.viewModel!.Groups, objIndex, pt); // Update array w reactivity
      // update folder in Notification
      this.viewModel!.Groups.forEach((element) => {
        if (element) {
          if (element === pt) {
            element = pt;
          }
        }
      });
      // update folder list in Notification listing
      this.$root.$emit(
        'update-notification-folder-listing-dropdown-with-notification',
        notification,
        pt,
      );
    }
  }
  // update folder list when folder deleted
  private NotificationFolderItemDeletedOperation(folderName: string) {
    const index = this.viewModel!.Groups.indexOf(folderName);
    if (index > -1) {
      this.viewModel!.Groups.splice(index, 1);
      this.selectedFolderItem = null;
      this.viewModel!.NotificationList.forEach((element) => {
        if (element.Folder) {
          if (element.Folder === folderName) {
            element.Folder = '';
          }
        }
      });
    }
    this.setActiveSubComponent('');
  }
  // update folder list when folder edited
  private NotificationFolderItemEditedOperation(
    oldfolderName: string,
    newfolderName: string,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const index = this.viewModel!.Groups.indexOf(oldfolderName);
    // Updated
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Vue.set(this.viewModel!.Groups, index, newfolderName); // Update array w reactivity
    // update folder in custom category
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.viewModel!.NotificationList.forEach((element) => {
      if (element.Folder) {
        if (element.Folder === oldfolderName) {
          element.Folder = newfolderName;
        }
      }
    });
    // update folder list in n listing
    this.$root.$emit('update-notification-folder-listing-dropdown', true);
  }

  // update folder list when folder added
  private NotificationFolderAdded(foldername: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const objIndex = this.viewModel!.Groups.findIndex(
      (groupname: string) => groupname === foldername,
    );
    if (objIndex === -1) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.Groups.push(foldername);
    }
  }

  // To reload list if folder item has no assoaiated dialogue item left in bulk change folder and bulk delete
  private NotificationFoldersListReload(notificationfolderList: string[]) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (this.viewModel!.Groups.length - 1 > notificationfolderList.length) {
      // Select all and change folder (all folders removed and only one new folder added)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (!this.viewModel!.Groups.includes(notificationfolderList[0])) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.viewModel!.Groups.push(notificationfolderList[0]);
      }
      // folder removed
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const difference: string[] = this.viewModel!.Groups.filter((x) => !notificationfolderList.includes(x));
      difference.forEach((value) => {
        if (value !== null || value !== this.TopLevel) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const index = this.viewModel!.Groups.indexOf(value);
          if (index !== -1) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.viewModel!.Groups.splice(index, 1);
          }
        }
      });
      this.ManageTopLevelFolder();
    } else if (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.Groups.length - 1 <
      notificationfolderList.length
    ) {
      // folder added
      const listA: string[] = notificationfolderList;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const listB: string[] = this.viewModel!.Groups;
      const missing = listA.filter((item) => listB.indexOf(item) < 0);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.Groups.push(missing.toString());
      this.ManageTopLevelFolder();
    } else if (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.Groups.length - 1 ===
      notificationfolderList.length
    ) {
      // folder added and removed at the same time
      const listA: string[] = notificationfolderList;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const listB: string[] = this.viewModel!.Groups;

      const missingremove = listB.filter((item) => listA.indexOf(item) < 0);
      missingremove.forEach((value) => {
        if (value !== null || value !== this.TopLevel) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const index = this.viewModel!.Groups.indexOf(value);
          if (index !== -1) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.viewModel!.Groups.splice(index, 1);
          }
        }
      });

      const missingadd = listA.filter((item) => listB.indexOf(item) < 0);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.Groups.push(missingadd.toString());
      this.ManageTopLevelFolder();
    }
  }

  // Top Level folder means null value in Group array.
  private ManageTopLevelFolder() {
    const topLevel = this.$t('Dialogue.TopLevel').toString();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (this.viewModel!.Groups.includes(topLevel)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const index = this.viewModel!.Groups.indexOf(topLevel, 0);
      if (index > -1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.viewModel!.Groups.splice(index, 1);
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.Groups.splice(0, 0, null!);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    } else if (this.viewModel!.Groups.includes('')) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const index = this.viewModel!.Groups.indexOf('', 0);
      if (index > -1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.viewModel!.Groups.splice(index, 1);
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.Groups.splice(0, 0, null!);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    } else if (this.viewModel!.Groups.includes(null!)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const index = this.viewModel!.Groups.indexOf(null!, 0);
      if (index > -1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.viewModel!.Groups.splice(index, 1);
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.Groups.splice(0, 0, null!);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.Groups.splice(0, 0, null!);
    }
  }
  // To update folder list if new folder created while Ceating or Updating new Dialoge item
  private UpdateFolderListInViewModel(notificationItems: NotificationItem[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    self.notificationFolders = [];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    self.notificationFolders.push(null!); // push top level folder
    notificationItems.forEach((notificationItem: NotificationItem) => {
      if (
        notificationItem.Folder &&
        notificationItem.Folder !== this.TopLevel &&
        notificationItem.Folder !== '' &&
        notificationItem.Folder !== null
      ) {
        if (
          self.notificationFolders &&
          !self.notificationFolders.includes(notificationItem.Folder)
        ) {
          self.notificationFolders.push(notificationItem.Folder);
        }
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.viewModel!.Groups = this.notificationFolders!;
  }

  // #endregion folder opertions

  // #region create new dialogue

  // Create new Item Button clicked
  private btnCreateNewNotificationItemClick() {
    if (this.localAllInformations !== null && this.localAllInformations !== undefined ) {
      this.localAllInformations.forEach((item)=> item.IsLinked = false);
    }
    if (this.localAllPrograms !== null && this.localAllPrograms !== undefined ) {
      this.localAllPrograms.forEach((item)=> item.IsLinked = false);
    }
    if (this.selectedNotificationItems.length === 1) {
      // get edit item pane data
      this.ChangeNotificationItem({
        selectedItem: this.selectedNotificationItems[0],
        moveToAnotherTab: true,
      });
      if (
        NotificationItem.prototype.CompareNotificationObjects({
          val1: this.notificationEditPaneValues,
          val2: this.selectedNotificationItems[0],
        }) && !this.IsSurveyItemDirty
      ) {
        this.CreateNewNotificationItem();
      } else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.CreateNewNotification;
        this.modalPopupContent = this.$t('CreateNew.Warning').toString();
      }
    } else {
      this.CreateNewNotificationItem();
    }
  }
  private CreateNewNotificationItem() {
    if (this.selectedNotificationItems.length > 0) {
      this.selectAllNotificationItems = false;
      setTimeout(() => {
        this.CreateNotification();
      }, 100);
    } else {
      this.CreateNotification();
    }
  }
  private CreateNotification() {
    this.createNewClicked = true;
    this.$root.$emit('clearNotificationCheckboxes');
    this.UncheckAllCheckBoxesForcefully();
    this.ClearSelectedItems();
    const newItem = NotificationItem.createEmpty();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    newItem.Folder = this.selectedFolderItemInListing!;
    this.selectedNotificationItems = [];
    setTimeout(() => {
      this.selectedNotificationItems.push(newItem);
    }, 100);
    // this.selectedFolderItem = null;
  }
  private CopyNotificationItemClick(val: NotificationItem) {
    if (this.selectedNotificationItems.length === 1) {
      // get edit item pane data
      this.ChangeNotificationItem({
        selectedItem: this.selectedNotificationItems[0],
        moveToAnotherTab: true,
      });
      this.CreateNewNotificationItemFromExisting(val);
    } else {
      this.CreateNewNotificationItemFromExisting(val);
    }
  }
  private CreateNewNotificationItemFromExisting(val: NotificationItem) {
    // check if select all was selected and uncheck it
    if (this.selectedNotificationItems.length > 0) {
      this.selectAllNotificationItems = false;
      setTimeout(() => {
        this.CreateNotificationbyCopying(val);
      }, 100);
    } else {
      this.CreateNotificationbyCopying(val);
    }
  }
  private CreateNotificationbyCopying(val: NotificationItem) {
    // this.createNewClicked = true;
    this.$root.$emit('clearNotificationCheckboxes');
    this.ClearSelectedItems();
    this.selectedNotificationItems = [];
    /* The copied item is just treated as the case of create new. therefore we are using the same emit
    as that of create new for setting the object in edit pane*/
    setTimeout(() => {
      this.selectedNotificationItems.push(val);
      this.$root.$emit('create-new-ojbect-update', this.selectedNotificationItems[0]);
    }, 10);
    setTimeout(() => {
      this.activeSubComponent = 'selectedNotification';
    }, 0);
    /* Activating the create item (list of dialoue options) pane as well in case uses cancel button and
    wants to create new dialogue*/
    if (this.localAllInformations !== null && this.localAllInformations !== undefined ) {
      this.localAllInformations.forEach((item)=> item.IsLinked = false);
    }
    if (this.localAllPrograms !== null && this.localAllPrograms !== undefined ) {
      this.localAllPrograms.forEach((item)=> item.IsLinked = false);
    }
    this.createNewClicked = true;
  }
  private NotificationCreated(
    ni: NotificationItem,
    isCreatedFromDropDown: boolean,
  ) {
    if (isCreatedFromDropDown) {
      // change notification type parameters when notification type changed from drop down.
      // reseting values of all elements when drop down changed
      this.selectedNotificationItems[0].Title = ni.Title;
      this.selectedNotificationItems[0].ImageFullUrl = ni.ImageFullUrl;
      this.selectedNotificationItems[0].ImageThumbnailUrl = ni.ImageThumbnailUrl;
      this.selectedNotificationItems[0].Description = '';
      this.selectedNotificationItems[0].NotificationType = ni.NotificationType;
      this.selectedNotificationItems[0].NotificationTypeId = ni.NotificationTypeId;
      this.selectedNotificationItems[0].NotificationTypeDescription = ni.NotificationTypeDescription;
      this.selectedNotificationItems[0].EnableClientShowResults = false;
      this.selectedNotificationItems[0].EnableResponseAnonymously = false;
      this.selectedNotificationItems[0].IsRespondOnlyOnce = false;
      this.selectedNotificationItems[0].FilterId = 0;
      this.selectedNotificationItems[0].IsSentPublish = false;
      this.selectedNotificationItems[0].Folder = '';
      this.selectedNotificationItems[0].Code = '';
      this.selectedNotificationItems[0].NotificationDimensions = undefined;
      this.selectedNotificationItems[0].CopiedNotificationId = 0;
    } else {
      // change notification type parameters when notification type changed from create new pane
      this.selectedNotificationItems[0].NotificationType = ni.NotificationType;
      this.selectedNotificationItems[0].NotificationTypeId = ni.NotificationTypeId;
      this.selectedNotificationItems[0].NotificationTypeDescription = ni.NotificationTypeDescription;
    }
    if (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.selectedNotificationItems[0]!.NotificationTypeId === NotificationEnum.ScaleStatements
    ) {
      // default description for scaling type dialogue
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.selectedNotificationItems[0]!.Description = this.$t('Dialogue.ScaleStatementsDefaultDescription').toString();
    }
    if (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.selectedNotificationItems[0]!.NotificationTypeId === NotificationEnum.RankOptionItems
    ) {
      // default description for ranking type dialogue
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.selectedNotificationItems[0]!.Description = this.$t('Dialogue.RankOptionsDefaultDescription').toString();
    }
    this.selectedNotificationItems[0].NotificationOptions = undefined;
    setTimeout(() => {
      this.$root.$emit('create-new-ojbect-update', this.selectedNotificationItems[0]);
    }, 10);
    this.activeSubComponent = 'selectedNotification';
  }

  private ChangeNotificationItem(object: any) {
    if (this.$refs.EditNotificationItem) {
      // get current values of program edit pane
      this.notificationEditPaneValues = Object.assign(
        {},
        (this.$refs.EditNotificationItem as Vue & {
          localNotificationItem: () => NotificationItem;
        }).localNotificationItem,
      );
      // to prevent watch of localEditedCustomCategoryItem if create new customCategory is click.
      if (!object.moveToAnotherTab) {
        this.localEditedNotificationItemItem = this.notificationEditPaneValues;
      }
    } else {
      this.localEditedNotificationItemItem = object.selectedItem;
    }
  }
  // #endregion create new dialogue

  // #region create new survey

  private btnCreateNewNotificationSurvey() {
    if (this.selectedNotificationItems.length === 1) {
      // get edit item pane data
      this.ChangeNotificationItem({
        selectedItem: this.selectedNotificationItems[0],
        moveToAnotherTab: true,
      });

      if (
        NotificationItem.prototype.CompareNotificationObjects({
          val1: this.notificationEditPaneValues,
          val2: this.selectedNotificationItems[0],
        }) && !this.IsSurveyItemDirty
      ) {
        this.CreateNewNotificationSurvey();
      } else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.CreateNewSurvey;
        this.modalPopupContent = this.$t('CreateNew.Warning').toString();
      }
    } else {
      this.CreateNewNotificationSurvey();
    }
  }
  private CreateNewNotificationSurvey() {
    if (this.selectedNotificationItems.length > 0) {
      this.selectAllNotificationItems = false;
      setTimeout(() => {
        this.CreateSurvey();
      }, 10);
    } else {
      this.CreateSurvey();
    }
  }
  private CreateSurvey() {
    // this.createNewClicked = false;
    this.$root.$emit('clearNotificationCheckboxes');
    this.UncheckAllCheckBoxesForcefully();
    this.selectedNotificationItems = [];
    const newItem = NotificationItem.createEmpty();
    newItem.NotificationType = 'Questionnaire';
    newItem.NotificationTypeId = 5;
    newItem.NotificationTypeDescription = 'Questionnaire Questions';
    newItem.IsSurvey = true;
    setTimeout(() => {
      this.selectedNotificationItems.push(newItem);
      this.$root.$emit('create-new-ojbect-update', this.selectedNotificationItems[0]);
      this.setActiveSubComponent(this.selectedNotification);
    }, 10);
    // this.selectedFolderItem = null;
  }
  private UncheckAllCheckBoxesForcefully() {
    setTimeout(() => {
      const w = document.getElementsByTagName('input');
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < w.length; i++) {
        if (w[i].type === 'checkbox') {
          w[i].checked = false;
        }
      }
    }, 30);
  }

  // #endregion create new survey

  // #region sorting view model listing

  private UpdateSortingNotificationListing(data: any) {
    const filteredNotificationListing: NotificationItem[] =
      data.filteredNotificationItems;
    const signalObject: SignalRModelRequest = data.signalObject;
    for (const notification of filteredNotificationListing) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const objIndex = this.viewModel!.NotificationList.findIndex(
        (obj: NotificationItem) => obj.Id === notification.Id,
      );
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Vue.set(this.viewModel!.NotificationList, objIndex, notification); // Update array w reactivity
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.viewModel!.NotificationList.sort(
      (a: NotificationItem, b: NotificationItem): number => {
        if (a.SortOrder > b.SortOrder) {
          return 1;
        }
        if (b.SortOrder > a.SortOrder) {
          return -1;
        }
        if (a.SortOrder === b.SortOrder) {
          return a.Title > b.Title ? 1 : a.Title === b.Title ? 0 : -1;
        }
        return 0;
      },
    );
    // call signalR
    this.SendNotificationRequestToQueue(
      signalObject,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.viewModel!.NotificationList,
    );
  }
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    notificationItems: NotificationItem[],
  ) {
    const signalR: NotificationSignalRModel = new NotificationSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    signalObject.JSONData = JSON.stringify(notificationItems);
    signalR.PerformNotificationSingleOperation(signalObject);
  }

  // #endregion sorting view model listing

  // #region utilities

  private GetRequestInfoFromStore() {
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    setTimeout(() => {
      const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
      this.applicationId = Number(reqInfo.ApplicationId);
      this.applicationInstanceId = Number(reqInfo.ApplicationInstanceId);
      this.EnableRankingDialogue = Boolean(reqInfo.EnableRankingDialogue);
      this.EnableScalingDialogue = Boolean(reqInfo.EnableScalingDialogue);
    }, 100);
  }
  private SwitchClassicMode() {
    this.classicMode = true;
    window.location.href =
      this.$EnvironmentBaseURL + 'Admin/Notification/IndexNotification';
  }
  // #endregion utilities

  // #region Export

  private ConfirmExportClick(object: any) {
    this.showLoading = true;
    let exportdata = {};
    this.segmentQuestions = object.segmentoptions;
    this.notificationItems = object.notificationOptions;
    this.exportFileName = object.exportFileName;

    const segmentIds: number[] = [];
    const notificationIdsList: number[] = [];

    if (
      this.notificationItems !== undefined &&
      this.notificationItems != null
    ) {
      this.notificationItems.forEach((element) => {
        notificationIdsList.push(Number(element.Id));
      });
    }

    if (this.segmentQuestions !== undefined && this.segmentQuestions != null) {
      this.segmentQuestions.forEach((element) => {
        segmentIds.push(Number(element.Id));
      });
    }

    if (segmentIds.length > 0 && notificationIdsList.length > 0) {
      exportdata = {
        foldername: '',
        groupname: '',
        _segmentQuestionIds: segmentIds,
        notificationIds: notificationIdsList,
      };
    } else {
      exportdata = {
        foldername: '',
        groupname: '',
        _segmentQuestionIds: null,
        notificationIds: notificationIdsList,
      };
    }
    this.exportPopupVisible = false;
    this.$axios({
      url: 'Notification/GenerateExcelResponseSummaryReportForGroupName',
      method: 'POST',
      headers: { Accept: 'application/vnd.ms-excel' },
      data: exportdata,
      responseType: 'blob', // important
    })
      .then((response) => {
        this.fileDownload(response.data, this.exportFileName);
        this.showLoading = false;
        if (response.data) {
          this.selectAllNotificationItems = false;
          this.SetNotificationLandingPage();
          this.$root.$emit('notification-get-all-without-watch');
        }
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  // #endregion Export

  // #region Show/Hide Panes

  private NotficationItemsSelected(pItems: NotificationItem[]) {
    const copyArr = Array.from(pItems); // copy array.
    // Set selected item collection for batch operations
    // this.selectedNotificationItems = copyArr;
    this.selectedNotificationItems = JSON.parse(JSON.stringify(copyArr));
    if (this.selectedNotificationItems.length === 1) {
      this.createNewClicked = false;
      this.setActiveSubComponent(this.selectedNotification);
    } else if (this.selectedNotificationItems.length > 1) {
      this.setActiveSubComponent(this.SelectedBatchNotification);
    } else {
      if (!this.createNewClicked) {
        if (this.activeSubComponent !== this.folders) {
          this.setActiveSubComponent('');
        }
      } else {
        this.setActiveSubComponent(this.SelectedCreateNewDialogue);
      }
    }
  }
  private setActiveSubComponent(comp: string) {
    this.activeSubComponent = comp;
  }
  private ManageFolders() {
    if (this.selectedNotificationItems.length === 1) {
      // get edit item pane data
      this.ChangeNotificationItem({
        selectedItem: this.selectedNotificationItems[0],
        moveToAnotherTab: true,
      });
      if (
        NotificationItem.prototype.CompareNotificationObjects({
          val1: this.notificationEditPaneValues,
          val2: this.selectedNotificationItems[0],
        }) && !this.IsSurveyItemDirty
      ) {
        this.ManageNotificationFolders();
      } else {
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSection_ModalPopup = this.folders;
        this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
      }
    } else {
      this.ManageNotificationFolders();
    }
  }
  private ManageNotificationFolders() {
    this.$root.$emit('clearNotificationCheckboxes');
    this.UncheckAllCheckBoxesForcefully();
    this.selectAllNotificationItems = false;
    this.ClearSelectedItems();
    setTimeout(() => {
      this.setActiveSubComponent(this.folders);
    }, 0);
  }
  private NotificationCreateItemCancelOperation() {
    this.createNewClicked = false;
    this.setActiveSubComponent('');
  }
  private ExportallDialogues() {
    this.$root.$emit('clearNotificationCheckboxes');
    this.selectAllNotificationItems = false;
    this.createNewClicked = false;
    this.ClearSelectedItems();
    this.exportPopupVisible = true;
  }
  private CancelandHideExportPopup() {
    this.exportPopupVisible = false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private notificationItemCancelOperation(pi: NotificationItem) {
    this.setActiveSubComponent('');
    this.ClearSelectedItems();
  }
  private NotificationFolderItemCancelOperation() {
    this.setActiveSubComponent('');
    this.selectedFolderItem = null;
    this.ClearSelectedItems();
  }
  private SetNotificationLandingPage() {
    if (this.selectedNotificationItems.length > 0) {
      this.selectAllNotificationItems = false;
      this.ClearSelectedItems();
      this.$root.$emit('clearNotificationCheckboxes', []);
    }
    this.setActiveSubComponent('');
  }
  private ClearSelectedItems() {
    this.selectedNotificationItems = [];
  }

  // #endregion Show/Hide Panes

  // #region Reload
  // #region Model Popup

  private SetModalPopupDefaultButtonText() {
    this.modalPopupShowCancelButton = true;
    this.modalPopupConfirmButtonText = this.$t('YesButton').toString();
    this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      if (this.showSection_ModalPopup === this.folders) {
        this.ManageNotificationFolders();
      } else
      if (this.showSection_ModalPopup === this.CreateNewNotification) {
        this.CreateNotification();
      } else if (this.showSection_ModalPopup === this.CreateNewSurvey) {
        this.CreateSurvey();
      } else if (this.selectedNotificationItems.length > 1) {
        this.SetNotificationLandingPage();
      } else if (this.showSection_ModalPopup === this.NotificationUpdated) {
        this.ReloadConfirmedNotificationItem();
        return;
      }
    } else {
      this.modalPopupVisible = false;
    }
    // this.$root.$emit('clearNotificationCheckboxes', []);
  }
  private getToolbarContainer(isMobileview: boolean) {
    if (isMobileview) {
      return 'toolbarContainerMobile';
    } else {
      return 'toolbarContainer';
    }
  }
  private getSurveyButtonContainer(isMobileview: boolean) {
    if (isMobileview) {
      return 'survey-mobile';
    } else {
      return '';
    }
  }
  private beforeDestroy() {
    this.$root.$off('batch-selected-notification-items-completed');
    this.$root.$off('selected-notification-folders-item-notification-completed');
  }
  // #endregion Model Popup
}
