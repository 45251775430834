






















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { required, minLength } from 'vuelidate/lib/validators';
import { NotificationSegmentQuestion } from '@/entities/Notification/NotificationSegmentQuestion';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';

import { NotificationItemParticipantInterest, NotificationSegmentQuestionWithChoices, NotificationParticipantGroup, NotificationFilterViewModel } from '@/entities/Notification/NotificationFilterViewModel';
@Component({
  validations: {
    filterTitle: {
      required,
      minLength: minLength(1),
    },
  },
  methods: {
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },
  },
  components: {
    ValidationSummary,
  },
})
export default class NotificationSelectAudienceFilter extends Vue {
  // Properties
  @Prop() private segmentQuestions?: NotificationSegmentQuestion[];
  @Prop() private filterProperties?: NotificationFilterViewModel;

  private availableInterests: NotificationItemParticipantInterest[] = [];
  private availableSegments: NotificationSegmentQuestion[] = [];
  private availableSegmentsChoices: NotificationSegmentQuestionWithChoices[] = [];
  private availableParticipantGroups: NotificationParticipantGroup[] = [];
  private filteredSegmentsChoices: NotificationSegmentQuestionWithChoices[] = [];
  private filterparticipantCount: any = null;
  private newparticipantFilter: any = null;
  private participantCount = 0;
  private filterTitle = '';
  private loadingImage: string = require('@/assets/Images/loading.png');
  private localNotificationLoading = false;

  // filter variables ids
  private seletedParticipantInterestId = 0;
  private seletedSegmentQuestionId = 0;
  private seletedSegmentChoiceId = 0;
  private seletedParticipantGroupId = 0;

  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private errors: string[] = [];
  private messages: string[] = [];
  // private filterNamePlaceholder: string = this.$t('Dialogue.Filter.FilterNamePlaceHolder').toString();
  private mounted() {
    // scroll the top of select filter
    this.$root.$emit('new-notification-edit-item-pane', true);
    if (this.filterProperties !== undefined && this.filterProperties !== null) {
      this.availableInterests = this.filterProperties.ParticipantInterest!;
      this.availableSegments = this.segmentQuestions!;
      this.availableSegmentsChoices = this.filterProperties.SegmentQuestionWithChoices!;
      this.availableParticipantGroups = this.filterProperties.ParticipantGroup!;
    }
    this.$root.$on('notificationDetailLoading', (loadingval: boolean) => {
      this.localNotificationLoading = loadingval;
    });
  }
  private filterSegmentChoices(event: any) {
    this.seletedSegmentChoiceId = 0;
    const segmentId = Number(event.target.value);
    this.seletedSegmentQuestionId = segmentId;
    this.filteredSegmentsChoices = [];
    this.availableSegmentsChoices.forEach((value) => {
      if (value.QuestionId === this.seletedSegmentQuestionId) {
        this.filteredSegmentsChoices.push(value);
      }
    });
  }
  private ChangeParticipantGroup(event: any) {
    // handles drop down change of participant group drop down
    const groupId = Number(event.target.value);
    this.seletedParticipantGroupId = groupId;
    // get participant count under these particiapnt Group
    this.GetParticipantsInfoByFilter();
  }
  private ChangeSegmentChoices(event: any) {
    // handles drop down change of Segment choice drop down
    const segmentchoiceId = Number(event.target.value);
    this.seletedSegmentChoiceId = segmentchoiceId;
    // get participant count under these segment choice
    this.GetParticipantsInfoByFilter();
  }
  private ChangeParticipantInterest(event: any) {
    // handles drop down change of Participant Interests drop down
    const interestId = Number(event.target.value);
    this.seletedParticipantInterestId = interestId;
    // get participant count under these interests
    this.GetParticipantsInfoByFilter();
  }
  private GetParticipantsInfoByFilter() {
    // get Participant count for the selected Participant group / Segment choice / Interest
    this.$axios
      .post<number>(
      '/Notification/GetParticipantsInfoByFilter', {
        interestId: this.seletedParticipantInterestId,
        segmentQuestionId: this.seletedSegmentQuestionId,
        segmentChoiceId: this.seletedSegmentChoiceId,
        selectedParticipantGroupId: this.seletedParticipantGroupId,
      },
    )
      .then((response) => {
        const participantcount = response.data;
        this.filterparticipantCount = participantcount;
        this.participantCount = this.filterparticipantCount.userFilters;
      })
      .catch(() => {
        // Log err
      });
  }
  // Save filter with selected Interest/ Participant Group / Segment choice
  private SaveFilter() {
    this.$v.$touch();
    this.validationErrorFields = [];
    this.ClearErrorsList();
    this.ClearMessagesList();
    this.filtervalidations();
    if (this.$v.$anyError) {
      if (this.$v.filterTitle!.$error === true) {
        this.validationErrorFields.push(this.$t('Title').toString());
      }
      if (this.errors.length) {
        this.FillValidationErrors();
      }
      this.visibleValidationSummary = true;
      return;
    }
    this.$v.$reset();
    if (this.errors.length) {
      this.FillValidationErrors();
      this.visibleValidationSummary = true;
      return;
    }
    this.$axios
      .post<number>(
      '/Notification/SaveFilter', {
        interestId: this.seletedParticipantInterestId,
        segmentId: this.seletedSegmentQuestionId,
        segmentChoiceId: this.seletedSegmentChoiceId,
        filterText: this.filterTitle,
        groupId: this.seletedParticipantGroupId,
      },
    )
      .then((response) => {
        const newfilter = response.data;
        this.newparticipantFilter = newfilter;
        // hide filter pane and show edit pane
        this.$root.$emit('notification-show-filter-pane', false);
        // set newly created filter in edit pane
        this.$emit('setFilter', this.newparticipantFilter);
      })
      .catch(() => {
        // Log error if required
      });
  }
  private CloseCreateFilter() {
    this.$root.$emit('notification-show-filter-pane', false);
  }
  // Clear Error List
  private ClearErrorsList() {
    this.errors = [];
  }
  // Clear Messages List
  private ClearMessagesList() {
    this.messages = [];
  }
  private FillValidationErrors() {
    const self = this;
    this.errors.forEach((item) => {
      self.validationErrorFields.push(item);
    });
  }
  private filtervalidations() {
    /* if no criterial is given for filter be created (i.e.) user ahs not selected
    any values from dropdown then validation error msg will be shown.*/
    if (this.seletedParticipantInterestId === 0 &&
       this.seletedParticipantGroupId === 0 &&
       this.seletedSegmentChoiceId === 0) {
      this.errors.push(
        this.$t('Dialogue.Filter.Validation').toString(),
      );
    }
  }
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  @Watch('filterProperties')
  private checkifchanged(val: NotificationFilterViewModel) {
  // to update all drop downs
    if (val !== undefined && val !== null) {
      this.availableInterests = this.filterProperties!.ParticipantInterest!;
      this.availableSegments = this.segmentQuestions!;
      this.availableSegmentsChoices = this.filterProperties!.SegmentQuestionWithChoices!;
      this.availableParticipantGroups = this.filterProperties!.ParticipantGroup!;
    }
  }
}
