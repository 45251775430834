




























































































































































import { Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { NotificationTypeForSurveyEnumParse } from '@/enums/NotificationEnum';
import { NotificationIconEnumParse, NotificationTypeEnumParse, NotificationTypeDescriptionEnumParse } from '@/enums/NotificationEnum';
import NotificationEnum from '@/enums/NotificationEnum';
import draggable from 'vuedraggable';
@Component({
  components: {
    draggable,
  },
})
export default class NotificationSurveyItemsListView extends Vue {
  @Prop() private notificationItemsList?: NotificationItem[];
  @Prop() private notificationFeedbacks?: number;
  @Prop() private isusersAnonymous?: boolean;
  @Prop() private parentNotificationId?: number;

  private localNotificationItems: NotificationItem[] = [];
  private sortednotificationItems: NotificationItem[] = [];
  private localNotificationLoading = false;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private uniqueIdArray: number[] = [];
  private isMobileView = false;
  private localParentNotificationId = 0;


  public get notificationEnum(): typeof NotificationEnum {
    return NotificationEnum;
  }
  private dragging = false;
  private mounted() {
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.localParentNotificationId = this.parentNotificationId!;
    this.localNotificationItems = [];
    if (this.notificationItemsList !== undefined && this.notificationItemsList !== null) {
    // this.localNotificationItems = JSON.parse(JSON.stringify(this.notificationItemsList!));
      this.getSubNotifications(this.notificationItemsList);
    } else {
      this.localNotificationItems = [];
    }
    this.$root.$on('notification-survey-item-added', (item: NotificationItem) => {
      this.AddItemInListing(item);
    });
    this.$root.$on('notification-survey-details', (val: NotificationItem) => {
      this.getSubNotifications(val.QuestionnaireNotifications!);
    });
    this.$root.$on('notificationSurveyDetailLoading', (loadingval: boolean) => {
      this.localNotificationLoading = loadingval;
    });
    this.$root.$on('notification-feedback-removed', (Id: number, leftResponses: number) => {
      this.updateResponsesInListing(Id, leftResponses);
    });
  }
  private getFontAwasomeIcon(notificationTypeId: number): string {
    return NotificationIconEnumParse.GetNotificationTypeIcon(notificationTypeId);
  }
  private checkMove() {
    // On Move event
  }
  // Draggable component on drag start event
  private draggingStart() {
    this.dragging = true;
  }
  // Draggable component on drag end event
  private draggingEnd() {
    this.dragging = false;
    this.UpdateSortOrder();
  }
  private UpdateSortOrder() {
    // updating listing of items
    this.localNotificationItems.forEach((value, index) => {
      value.SortOrder = index;
    });
    // setting new sorted order in virtualSurveySubNotificationList in NotificationSurvey.vue
    this.$root.$emit('notification-survey-item-edited');
  }
  private getSubNotifications(subNotificationItems: NotificationItem[]) {
    this.localNotificationItems = [];
    subNotificationItems.forEach((value) => {
      value.EnableResponseAnonymously = this.isusersAnonymous!;
      const notItem: NotificationItem = JSON.parse(JSON.stringify(value));
      this.localNotificationItems.push(notItem);
    });
  }
  private getDialogueTypeText(typeId: number): string {
    return NotificationTypeForSurveyEnumParse.GetNotificationTypeEnum(typeId);
  }
  private DeleteNotificationItemFromListing(item: NotificationItem) {
    if (window.confirm(this.$t('Dialogue.DeleteItemSurveyWarning').toString())) {
      const objIndex = this.localNotificationItems.findIndex(
        (obj: NotificationItem) => obj.Id === item.Id,
      );
      if (objIndex > -1) {
        this.localNotificationItems.splice(objIndex, 1);
        this.$root.$emit('notification-survey-item-edited');
      }
    }
  }
  private CopyNotificationItemFromListing(item: NotificationItem) {
    if (window.confirm(this.$t('Dialogue.CopyItemSurveyWarning').toString())) {
      let newNotification = NotificationItem.createEmpty();
      newNotification = JSON.parse(JSON.stringify(item));
      newNotification.IsNewSurveyItem = false;
      newNotification.IsCreateNewItemInSurvey = true;
      newNotification.SortOrder = this.localNotificationItems[this.localNotificationItems.length - 1].SortOrder + 1;

      newNotification.NotificationType = NotificationTypeEnumParse.GetNotificationTypeEnum(newNotification.NotificationTypeId);

      newNotification.NotificationType = NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(newNotification.NotificationTypeId);
      newNotification.HighChartData = undefined;
      newNotification.RankingHighChartData = undefined;
      newNotification.ScalingHighChartData = undefined;
      newNotification.UserFeedback = undefined;
      newNotification.RecipientCount = 0;
      newNotification.NotificationFeedbacks = 0;
      newNotification.Id = this.getUniqueId();
      this.localNotificationItems.push(newNotification);
      this.$root.$emit('notification-survey-item-edited');
    }
  }
  private EditNotificationItemFromListing(item: NotificationItem, landAtResults: boolean) {
    this.$root.$emit('notification-hide-edit-item-buttons', false);
    this.$emit('editNotificationItemsSurvey', item, landAtResults);
  }
  private UpdateIteminListing(item: NotificationItem) {
    const objIndex = this.localNotificationItems.findIndex(
      (obj: NotificationItem) => obj.Id === item.Id,
    );
    if (objIndex > -1) {
      this.localNotificationItems[objIndex] = item;
    }
  }
  private AddItemInListing(item: NotificationItem) {
    item.Id = this.getUniqueId();
    this.localNotificationItems.push(item);
  }
  private updateResponsesInListing(Id: number, responsenum: number) {
    const objIndex = this.localNotificationItems.findIndex(
      (obj: NotificationItem) => obj.Id === Id,
    );
    if (objIndex > -1) {
      this.localNotificationItems[objIndex].NotificationFeedbacks = responsenum;
    }
  }
  private getUniqueId(): number {
    let newId = -1;

    if (this.localNotificationItems !== undefined && this.localNotificationItems !== null && this.localNotificationItems.length > 0) {
      this.localNotificationItems.forEach((item) => {
        this.uniqueIdArray.push(item.Id);
      });
      newId = -Math.abs(9999 + this.localNotificationItems[this.localNotificationItems.length - 1].Id);
      while (this.uniqueIdArray.includes(newId)) {
        newId--;
      }
    }
    return newId;
  }
  private clickOnAddSurveyItem() {
    this.$root.$emit('notification-create-sub-item-button-click');
  }
  @Watch('localNotificationItems')
  private onlocalItemPropertyChange(newval: NotificationItem[]) {
    this.$emit('setNotificationItemsSurvey', newval);
  }
}
