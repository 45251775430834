




















































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import draggable from 'vuedraggable';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { NotificationFolder } from '@/entities/Notification/NotificationFolder';
import { StoreHelper } from '@/store/StoreHelper';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { NotificationSignalRModel } from '@/signalRRequest/Notification/NotificationSignalRModel';
import { NotificationDetailViewModel } from '@/entities/Notification/NotificationDetailViewModel';
import moment from 'moment-timezone';
import NotificationEnum, {
  NotificationIconEnumParse,
} from '@/enums/NotificationEnum';

@Component({
  components: {
    loading,
    ModalPopup,
    draggable,
  },
})
export default class NotificationListView extends Vue {
  // constants
  private readonly EditBoth = 'both';
  private readonly EditIcon = 'icon';
  private readonly EditFeatured = 'featured';
  private readonly whiteColor: string = '#FFFFFF';
  private readonly Notification = 'Notification';
  private readonly WarningMessage = 'WarningMessage';
  private readonly NotificationUpdateFolder = 'NotificationUpdateFolder';
  private readonly NotificationSorting = 'NotificationSorting';
  private readonly NotificationDialogueCopy = 'NotificationDialogueCopy';
  private readonly NotificationSurveyCopy = 'NotificationSurveyCopy';
  private dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat();
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private timeZones: string = this.storeHelper.GetActiveEventIanaTimeZoneFromStore();
  //   props
  @Prop() private selectAllItems?: boolean;
  @Prop() private applicationborderColor?: string;
  @Prop() private notificationItems?: NotificationItem[];
  @Prop() private folders?: string[];
  @Prop() private editingNotificationItem?: NotificationItem;
  @Prop() private folderItems?: NotificationFolder[];
  //  Modal popup
  private modalPopupVisible = false;
  private showCloseButton = true;
  private showSectionModalPopup = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private modalPopupContent = '';

  // Local valiables
  private dragging = false;
  private emptySelectedItems = true;
  private notificationFolders?: string[] = [];
  private filteredNotificationItems: NotificationItem[] = [];
  private temporarySelectedItems: NotificationItem[] = [];
  private isMobileView = false;
  private selectedItems: NotificationItem[] = [];
  private selectednotificationItem: NotificationItem = NotificationItem.createEmpty();
  private selectedCheckboxCount = 0;
  private showLoading = false;
  private All = '';
  private TopLevel = '';
  private selectedFolderName: string = this.All;
  private selectedStatusOption: string = this.All;
  private status: string[] = this.getStatusStrings();
  private selectedItemId = 0;
  private selectednotificationItemValue: NotificationItem[] = [];
  private loaderBorderColor = '';
  private selectedFolderForStatusFilteration = '';
  private updateFilterandStatus =  false;
  private IsSurveySubItemDirty = false;
  private isIEbrowser = false;
  private showCaret?: boolean = false;
  private notificationCloneSource?: NotificationItem = NotificationItem.createEmpty();
  private isCopyOperation?: boolean = false;
  private otherAdminUpdatingList?: boolean = false;
  private updatedNotificationId?: number = 0;
  private created() {

    if (navigator.appName === 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
      this.isIEbrowser = true;
    }
    this.GetAllNotifications();
  }
  private mounted() {
    this.initializeTimeFormat();
    this.All = this.$t('Dialogue.All').toString();
    this.TopLevel = this.$t('Dialogue.TopLevel').toString();
    // load folder dropdown
    this.notificationFolders = this.folders;
    this.BindFoldersDropdown(this.folders);
    // set Top level by default
    this.OnFolderChange(this.All);
    this.selectedFolderName = this.All;
    // Check for mobile view
    if (window.innerWidth <= 480) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.$root.$on(
      'update-notification-folder-listing-dropdown',
      (updateListing: boolean) => {
        if (updateListing) {
          this.UpdateFolderList(this.notificationItems!);
        }
      },
    );

    this.$root.$on(
      'notification-item-bulk-delete-operation',
      (notification: SignalRModelRequest) => {
        notification.SelectedIds.forEach((element) => {
          this.notificationItemDeletedOperation(element);
        });
        this.UpdateFolderList(this.notificationItems!);
      },
    );
    this.$root.$on(
      'notification-item-bulk-clear-responses-operation',
      (notification: SignalRModelRequest) => {
        notification.SelectedIds.forEach((element) => {
          this.notificationItemResponsesClearedOperation(element);
        });
        this.UpdateFolderList(this.notificationItems!);
      },
    );
    this.$root.$on(
      'notification-item-bulk-folder-change-operation',
      (notification: SignalRModelRequest) => {
        notification.SelectedIds.forEach((element) => {
          this.notificationItemFolderChangeOperation(
            element,
            notification.JSONData,
          );
        });
        this.UpdateFolderList(this.notificationItems!);
      },
    );
    this.$root.$on(
      'selected-notification-item-notification-completed',
      (notification: SignalRModelRequest) => {

        const signalR: NotificationSignalRModel = new NotificationSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.NotificationSignalRModelResponseReceived(notification);
        if (notification.IsSuccess) {
          this.PerformOperationOnList(notification);
        }
      },
    );
    this.$root.$on(
      'selected-notification-item-survey-completed',
      (notification: SignalRModelRequest) => {

        const signalR: NotificationSignalRModel = new NotificationSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        signalR.NotificationSignalRModelResponseReceived(notification);
        if (notification.IsSuccess) {
          this.PerformOperationOnList(notification);
        }
      },
    );
    this.$root.$on('disable-notification-items', (notificationId: number[]) => {
      notificationId.forEach((element) => {
        this.filteredNotificationItems.find(
          (x) => x.Id === element,
        )!.DisableItem = true;
        this.notificationItems!.find(
          (x) => x.Id === element,
        )!.DisableItem = true;
      });
    });
    this.$root.$on('enable-notification-items', (notificationIds: number[]) => {
      notificationIds.forEach((element): void => {
        this.filteredNotificationItems.find(
          (x) => x.Id === element,
        )!.DisableItem = false;
        this.notificationItems!.find(
          (x) => x.Id === element,
        )!.DisableItem = false;
      });
    });
    this.$root.$on('reload-notification-detail', (ni: NotificationItem) => {
      let selectedNotificationItem: NotificationItem = NotificationItem.createEmpty();
      if (ni !== undefined && ni !== null) {
        selectedNotificationItem = ni;
        this.updateFilterandStatus = true;
      } else {
        selectedNotificationItem = this.selectedItems[0];
      }
      this.selectedItems = [];
      selectedNotificationItem.StyleDefaultDiv = this.whiteColor;
      this.selectedItems.push(selectedNotificationItem);
      this.selectedCheckboxCount = 1;
      if (selectedNotificationItem.NotificationTypeId !== NotificationEnum.Survey) {
        this.FillNotificationDetails(selectedNotificationItem);
      } else {
        this.FillSurveyDetails(selectedNotificationItem);
      }
    });
    this.$root.$on('reload-sub-notification-detail', (selectedNotificationItem: NotificationItem) => {
      this.FillNotificationDetails(selectedNotificationItem);
      if(selectedNotificationItem!==null && selectedNotificationItem.QuestionnaireNotifications!== null) {
        this.FillSurveyDetails(selectedNotificationItem);
      }
    });
    this.$root.$on('notification-get-all-without-watch', () => {
      this.GetAllNotificationsWithoutWatch();
    });
    this.$root.$on('notification-feedback-removed', (Id: number, leftResponses: number) => {
      const objIndex = this.filteredNotificationItems.findIndex(
        (obj: NotificationItem) => obj.Id === Id,
      );
      if (objIndex > -1) {

        this.filteredNotificationItems[objIndex].NotificationFeedbacks = leftResponses;
      }
    });
    this.$root.$on('notification-survey-sub-item-dirty', (val: boolean) => {
      this.IsSurveySubItemDirty = val;
      if (this.selectedItems[0] !== undefined) {
        this.selectedItems[0].IsItemDirty = val;
      }
    });
    this.$root.$on('notification-update-isting-without-closing-edit-pane', (val: boolean) => {
      this.updateFilterandStatus = val;
    });
    this.$root.$on('notification-updated-listing-by-other-admin', (val: boolean, Id: number) => {
      this.otherAdminUpdatingList = val;
      this.updatedNotificationId = Id;
    });
    this.$root.$on('create-new-ojbect-update', (val: NotificationItem) => {
      this.selectedItems = [];
      this.selectedItems = [val];
    });
    this.$root.$on('notification-uncheck-checkbox-forcefully', () => {
      this.uncheckCheckBoxForcefully();
    });

    this.$root.$on(
      'update-notification-folder-listing-dropdown-with-notification',
      (notification: SignalRModelRequest, pt: string) => {
        const signalR: NotificationSignalRModel = new NotificationSignalRModel(
          this.$root,
          this.$axios,
          this.$store,
          this.$t,
        );
        if (signalR.IsLoggedInUserIsMe(notification)) {
          this.UpdateFolderList(this.notificationItems!);
          if (
            this.selectedFolderName !== this.All &&
            this.selectedFolderName !== ''
          ) {
            if (this.selectedFolderName) {
              const objIndex = this.notificationFolders!.findIndex(
                (obj: string) => obj === this.selectedFolderName,
              );
              if (objIndex === -1) {
                this.UpdateSelectedFolder(pt);
              }
            }
          }
        } else {
          this.UpdateFolderList(this.notificationItems!);
          if (
            this.selectedFolderName !== this.All &&
            this.selectedFolderName !== ''
          ) {
            this.UpdateSelectedFolder(pt);
            this.modalPopupVisible = true;
            this.showCloseButton = false;
            this.showSectionModalPopup = this.NotificationUpdateFolder;
            this.modalPopupShowCancelButton = false;
            this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
            this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
            this.modalPopupContent = this.$t('Message.FolderUpdatedBy', {
              username: notification.UserName,
            }).toString();
          }
        }
      },
    );
    this.$root.$on('clearNotificationCheckboxes', () => {
      this.ClearAllCheckBoxes();
      this.resetNotificationsItemsBackgroundColor();
    });
    this.$root.$on('update-reminder-count-of-notification-item',(id: number,reminderCount: number) => {
      this.filteredNotificationItems.forEach((item)=> {
        if(item.Id === id) {
          item.CountOfRemainingRemindersToSend = reminderCount;
        }
      });
      this.$root.$emit('notification-show-overlay', false);
    });
  }
  // #region Notification list update with SignalR

  private PerformOperationOnList(notification: SignalRModelRequest) {
    {
      if (
        notification.Module === ModuleEnum.Dialogue &&
        notification.SubModule === SubModuleEnum.SingleDelete
      ) {
        if (notification.SelectedIds.length > 0) {
          if (
            this.selectedItems.length === 1 &&
            this.selectedItems[0].Id === notification.SelectedIds[0]
          ) {
            this.CheckIfDeletedItemIsOpenInEditMode(notification);
          } else {
            this.notificationItemDeletedOperation(notification.SelectedIds[0]);
          }
        }
      } else if (
        notification.Module === ModuleEnum.Survey &&
        notification.SubModule === SubModuleEnum.SingleDelete
      ) {
        if (notification.SelectedIds.length > 0) {
          if (
            this.selectedItems.length === 1 &&
            this.selectedItems[0].Id === notification.SelectedIds[0]
          ) {
            this.CheckIfDeletedItemIsOpenInEditMode(notification);
          } else {
            this.notificationItemDeletedOperation(notification.SelectedIds[0]);
          }
        }
      } else if (
        notification.Module === ModuleEnum.Dialogue &&
      notification.SubModule === SubModuleEnum.Sorting
      ) {
        if (notification.SelectedIds.length > 0) {
          if (this.selectedItems.length > 0) {
            this.CheckIfSortingItemIsOpenInEditMode(notification);
          } else {
            const signalR: NotificationSignalRModel = new NotificationSignalRModel(
              this.$root,
              this.$axios,
              this.$store,
              this.$t,
            );
            if (!signalR.IsLoggedInUserIsMe(notification)) {
              this.$root.$emit('reload-notification-data');
            }

          }
        }
      } else {
        this.$root.$emit(
          'notification-item-edit-operation-with-notification',
          notification,
        );
        // this.resetNotificationsItemsBackgroundColor();
      }
    }
  }
  private CheckIfDeletedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {

    const signalR: NotificationSignalRModel = new NotificationSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      this.notificationItemDeletedOperation(notification.SelectedIds[0]);
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.Notification;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupContent = this.$t('Message.ItemDeletedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedItemId = notification.SelectedIds[0];
    }
  }
  private CheckIfUpdatedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {

    const signalR: NotificationSignalRModel = new NotificationSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      // Do nothing
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      // this.showSectionModalPopup = this.NotificationSharedEventsUpdated;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedItemId = notification.SelectedIds[0];
    }
  }
  private CheckIfSortingItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {

    const signalR: NotificationSignalRModel = new NotificationSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      this.$root.$emit('reload-notification-data');
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.NotificationSorting;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      if (this.selectedItems.length === 1) {
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
      } else {
        this.modalPopupContent = this.$t('Message.MultipleItemsUpdatedBy', {
          username: notification.UserName,
        }).toString();
      }
    }
  }

  // #endregion Notification list update with SignalR

  // #region  folder and filter functions

  // bind Prop Folders to drop down
  private BindFoldersDropdown(newfolders?: string[]) {
    // load folder dropdown
    if (newfolders) {
      this.notificationFolders = Object.assign(newfolders);
      // remove empty value
      if (this.notificationFolders) {
        // Avoid binding empty string, top level string in folder array
        this.notificationFolders = this.notificationFolders.filter(
          (e) => e !== '' && e !== this.All && e !== null && e !== this.TopLevel,
        );
      }
    }
  }
  private GetAllNotificationsWithoutWatch() {
    // set Top level by default
    this.OnFolderChange(this.All);
    this.selectedFolderName = this.All;
  }
  // Get All notifications
  private GetAllNotifications() {
    const self = this;
    self.filteredNotificationItems = [];
    this.notificationItems!.forEach((value) => {
      self.filteredNotificationItems.push(value);
    });
  }
  // Get Notification in top Level of folder
  private GetTopLevelNotifications() {
    const self = this;
    self.filteredNotificationItems = [];
    this.notificationItems!.forEach((value) => {
      if (value.Folder === null || value.Folder === '' || value.Folder === this.TopLevel) {
        self.filteredNotificationItems.push(value);
      }
    });
  }
  // Get notification in selected folder
  private GetSelectedFolderNotifications(selectedFolderName: string) {
    const self = this;
    self.filteredNotificationItems = [];
    this.notificationItems!.forEach((value) => {
      if (value.Folder === selectedFolderName) {
        self.filteredNotificationItems.push(value);
      }
    });
  }
  // Get Published notifications when All filter is selected
  private GetAllPublishedNotifications() {
    const self = this;
    self.filteredNotificationItems = [];
    this.notificationItems!.forEach((value) => {
      if (value.IsPublished === true) {
        self.filteredNotificationItems.push(value);
      }
    });
  }
  // Get Published notifications when All filter is selected
  private GetAllUnpublishedNotifications() {
    const self = this;
    self.filteredNotificationItems = [];
    this.notificationItems!.forEach((value) => {
      if (value.IsPublished === false) {
        self.filteredNotificationItems.push(value);
      }
    });
  }
  // Get Published notifications from particular selected folder
  private GetPublishedNotifications(fromFolder: any) {
    const self = this;
    self.filteredNotificationItems = [];
    if (fromFolder === this.TopLevel || fromFolder === this.$t('Dialogue.TopLevel').toString() || fromFolder === null) {
      this.notificationItems!.forEach((value) => {
        if (value.IsPublished === true && (value.Folder === '' || value.Folder === null)) {
          self.filteredNotificationItems.push(value);
        }
      });
    } else {
      this.notificationItems!.forEach((value) => {
        if (value.IsPublished === true && value.Folder === fromFolder) {
          self.filteredNotificationItems.push(value);
        }
      });
    }
  }
  // Get Unpublished notifications from particular selected folder
  private GetUnpublishedNotifications(fromFolder: any) {
    const self = this;
    self.filteredNotificationItems = [];
    if (fromFolder === this.TopLevel || fromFolder === this.$t('Dialogue.TopLevel').toString() || fromFolder === null) {
      this.notificationItems!.forEach((value) => {
        if (value.IsPublished === false  && (value.Folder === '' || value.Folder === null)) {
          self.filteredNotificationItems.push(value);
        }
      });
    } else {
      this.notificationItems!.forEach((value) => {
        if (value.IsPublished === false && value.Folder === fromFolder) {
          self.filteredNotificationItems.push(value);
        }
      });
    }
  }
  // handles folders drop down
  private OnFolderChange(selectedFolderName: string) {
    if (!this.updateFilterandStatus) {
      this.$root.$emit('notifications-uncheck-select-all');
      this.ClearAllCheckBoxes();
      this.$root.$emit('set-notification-landing-page', true);
      this.selectedCheckboxCount = 0;
      this.resetNotificationsItemsBackgroundColor();
    }
    this.selectedFolderForStatusFilteration = selectedFolderName;
    const element: HTMLInputElement = document.getElementById('nsi_' + this.All) as HTMLInputElement;
    // set filter to All whenver folder is changed
    element.checked = true;
    this.selectedStatusOption = this.All;
    if (selectedFolderName !== undefined && this.notificationItems != null) {
      if (selectedFolderName === this.All) {
        this.GetAllNotifications();
      } else if (selectedFolderName === this.TopLevel) {
        this.GetTopLevelNotifications();
      } else {
        this.GetSelectedFolderNotifications(selectedFolderName);
      }
    }
  }

  // handle filter down change. (All/ Published/ Unpublished items in selected folder)
  private OnStatusOptionChange(selectedStatusOption: string) {
    if (!this.otherAdminUpdatingList) {
      this.showCaret = false;
      if (selectedStatusOption !== this.$t('Dialogue.All').toString()) {
        this.showCaret = true;
      }
      this.selectedStatusOption = selectedStatusOption;
      const element: HTMLInputElement = document.getElementById('nsi_' + selectedStatusOption) as HTMLInputElement;
      element.checked = true;
      const statusDropDOwn: HTMLBodyElement = document.getElementById('status_Dropdown') as HTMLBodyElement;
      statusDropDOwn.classList.remove('show');
      if (!this.updateFilterandStatus) {
        this.ClearAllCheckBoxes();
        this.UncheckSelectAllCheckbox();
        this.resetNotificationsItemsBackgroundColor();
      }
      if (selectedStatusOption !== undefined && this.notificationItems != null) {
        if (this.selectedFolderForStatusFilteration === this.All) {
          if (selectedStatusOption === this.status[0]) {
            this.GetAllNotifications();
          } else if (selectedStatusOption === this.status[1]) {
            this.GetAllPublishedNotifications();
          } else if (selectedStatusOption === this.status[2]) {
            this.GetAllUnpublishedNotifications();
          }
        } else {
          if (selectedStatusOption === this.status[0]) {
            this.OnFolderChange(this.selectedFolderForStatusFilteration);
          } else if (selectedStatusOption === this.status[1]) {
            this.GetPublishedNotifications(
              this.selectedFolderForStatusFilteration,
            );
          } else if (selectedStatusOption === this.status[2]) {
            this.GetUnpublishedNotifications(
              this.selectedFolderForStatusFilteration,
            );
          }
        }
      }
    } else {
      this.updatedItemSelectedforBulkOperation(this.updatedNotificationId!);
    }
  }
  private updatedItemSelectedforBulkOperation(Id: number) {
    const updatedNotification: NotificationItem =  this.notificationItems!.find(
      (x) => x.Id === Id,
    )!;
    const objIndex: number =  this.filteredNotificationItems.findIndex(
      (x) => x.Id === Id,
    )!;
    this.filteredNotificationItems[objIndex].Title = updatedNotification.Title;
    this.filteredNotificationItems[objIndex].Description = updatedNotification.Description;
    this.filteredNotificationItems[objIndex].ImageFullUrl = updatedNotification.ImageFullUrl;
    this.filteredNotificationItems[objIndex].ImageThumbnailUrl = updatedNotification.ImageThumbnailUrl;
    this.filteredNotificationItems[objIndex].IsSentPublish = updatedNotification.IsSentPublish;
    this.filteredNotificationItems[objIndex].EnableClientShowResults = updatedNotification.EnableClientShowResults;
    this.filteredNotificationItems[objIndex].EnableResponseAnonymously = updatedNotification.EnableResponseAnonymously;
    this.filteredNotificationItems[objIndex].IsRespondOnlyOnce = updatedNotification.IsRespondOnlyOnce;
    this.filteredNotificationItems[objIndex].NotificationOptions = updatedNotification.NotificationOptions;
    this.filteredNotificationItems[objIndex].NotificationDimensions = updatedNotification.NotificationDimensions;
    this.filteredNotificationItems[objIndex].Folder = updatedNotification.Folder;
    this.filteredNotificationItems[objIndex].Code = updatedNotification.Code;
    this.filteredNotificationItems[objIndex].Filter = updatedNotification.Filter;
    this.filteredNotificationItems[objIndex].FilterId = updatedNotification.FilterId;
    this.filteredNotificationItems[objIndex].StyleDefaultDiv = updatedNotification.StyleDefaultDiv;
    this.filteredNotificationItems[objIndex].ScheduleDate = updatedNotification.ScheduleDate;
  }
  // #endregion folder and filter functions

  // #region Bulk operation

  private notificationItemDeletedOperation(Id: number) {
    const objIndex = this.notificationItems!.findIndex(
      (obj: NotificationItem) => obj.Id === Id,
    );
    if (objIndex > -1) {
      const folderName = this.notificationItems![objIndex].Folder;
      this.notificationItems!.splice(objIndex, 1);
      const lefetitemsCount: NotificationItem[] = this.notificationItems!.filter((i) => i.Folder === folderName);
      if (lefetitemsCount.length === 0) {
        this.selectedFolderName = this.All;
        this.OnFolderChange(this.selectedFolderName);
      }
    }
  }
  private notificationItemResponsesClearedOperation(Id: number) {
    const nIndex = this.notificationItems!.findIndex(
      (obj: NotificationItem) => obj.Id === Id,
    );
    if (nIndex > -1) {
      this.notificationItems![nIndex].NotificationFeedbacks = 0;
    }
    this.OnFolderChange(this.selectedFolderName);
  }
  private notificationItemFolderChangeOperation(
    Id: number,
    foldernameJson: string,
  ) {
    const foldernameParsed = JSON.parse(foldernameJson);
    const folderUpdatedName = foldernameParsed.notificationGroupFolder[0];
    const nIndex = this.notificationItems!.findIndex(
      (obj: NotificationItem) => obj.Id === Id,
    );
    if (nIndex > -1) {
      this.notificationItems![nIndex].Folder = folderUpdatedName;
    }
    this.selectedFolderName = this.All;
    this.OnFolderChange(this.All);
  }

  // #endregion Bulk Operations

  // #region  Folder Operations

  private UpdateSelectedFolder(name: string) {
    this.selectedFolderName = name;
    // this.OnFolderChange(this.selectedFolderName);
  }

  private OnFolderChangeWithWatch(selectedFolderName: string) {
    if (selectedFolderName === this.All) {
      this.GetAllNotifications();
    } else if (
      selectedFolderName === '' ||
      selectedFolderName === null ||
      selectedFolderName === this.TopLevel
    ) {
      this.GetTopLevelNotifications();
    } else {
      this.GetSelectedFolderNotifications(selectedFolderName);
    }
  }

  // Update folder list
  private UpdateFolderList(notificationItems: NotificationItem[]) {
    const self = this;
    self.notificationFolders = [];
    notificationItems.forEach((notificationItem: NotificationItem) => {
      if (
        notificationItem.Folder &&
        notificationItem.Folder !== this.TopLevel &&
        notificationItem.Folder !== '' &&
        notificationItem.Folder !== null
      ) {
        if (
          self.notificationFolders &&
          !self.notificationFolders.includes(notificationItem.Folder)
        ) {
          self.notificationFolders.push(notificationItem.Folder);
        }
      }
    });
    this.$root.$emit(
      'notification-viewmodel-folder-list-update-after-bulk-operation',
      this.notificationFolders,
    );
  }

  // #endregion Folder Operations

  // #region UI control response

  // Get strings of All/ Publish/ Unpublish Filter
  private getStatusStrings(): string[] {
    const all = this.$t('Dialogue.All').toString();
    const published = this.$t('Dialogue.PublishedLower').toString();
    const unpublished = this.$t('Dialogue.UnpublishedLower').toString();
    const array: string[] = [all, published, unpublished];
    return array;
  }
  // Draggable component on drag start event
  private draggingStart() {
    this.dragging = true;
  }
  // Draggable component on drag end event
  private draggingEnd() {
    this.dragging = false;
    this.SaveNotificationSortOrder();
  }
  private ClearAllCheckBoxes() {
    this.selectedItems = [];
  }
  // Uncheck Select all checkboxe
  private UncheckSelectAllCheckbox() {
    this.$root.$emit('notifications-uncheck-select-all');
    this.emptySelectedItems = false;
  }
  private GetMoveToAnotherItemMessage(): string {
    return this.$t('ChangeScreenMessage').toString();
  }
  private resetNotificationsItemsBackgroundColor() {
    this.notificationItems!.forEach((item) => {
      item.StyleDefaultDiv = '#f9f9f9';
    });
  }
  // get icon of Notification type and set in listing
  private getFontAwasomeIcon(notificationTypeId: number): string {
    return NotificationIconEnumParse.GetNotificationTypeIcon(
      notificationTypeId,
    );
  }
  private initializeTimeFormat() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    // if timezone is allowed in application then date times will be formatted accordingly
    if (this.timeZones !== '' && this.timeZones !== null && !this.isIEbrowser) {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: this.timeZones,
      });
    } else {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    }
  }
  private formatTime(pi: NotificationItem) {
    if (pi.PublishedDateTime !== null && pi.PublishedDateTime !== undefined) {
      const datepublished: Date = moment(pi.PublishedDateTime).toDate();
      if (!this.isIEbrowser) {
        // for all browser (except IE) date is formatted with browser settings
        return this.dateFormat.format(datepublished).toUpperCase();
      } else {
        // for IE browser date is formatted manually
        const ieDateForamt = moment(datepublished).tz(this.timeZones).format('DD-MM-YYYY, h:mm a');
        return ieDateForamt;
      }
    }
  }
  private uncheckCheckBoxForcefully() {
    if (this.editingNotificationItem! !== null) {
      const element: HTMLInputElement = document.getElementById('ni_' + this.editingNotificationItem!.Id) as HTMLInputElement;
      element.checked = false;
    }
  }
  private formatScheduleTime(pi: NotificationItem): string {
    const datescheduled: Date = moment(pi.ScheduleUTCDate).toDate();
    if (!this.isIEbrowser) {
    // for all browser (except IE) date is formatted with browser settings
      return this.dateFormat.format(datescheduled).toUpperCase();
    } else {
    // for IE browser date is formatted manually
      const ieDateForamt = moment(datescheduled).tz(this.timeZones).format('DD-MM-YYYY, h:mm a');
      return ieDateForamt;
    }
  }
  private UpdateFolderInListing() {
    this.$root.$emit('reload-notification-data');
  }
  // Open Create new pane when link in No dialogue empty screen is clicked
  private OpenCreateNewDailoguepane() {
    this.$emit('create-new-dialogue-click');
  }
  private async updateSelectedItem(selectedNotificationItem: NotificationItem) {
    this.UncheckSelectAllCheckbox();
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await this.ClearAllCheckBoxes();
    this.isCopyOperation = false;
    this.IsSurveySubItemDirty = false;
    // make background white of selected customCategory item in customCategory list.
    this.resetNotificationsItemsBackgroundColor();
    if (selectedNotificationItem != null) {
      selectedNotificationItem.StyleDefaultDiv = this.whiteColor;
      this.selectedItems.push(selectedNotificationItem);
      this.selectedCheckboxCount = 1;
      if (this.selectedItems[0].NotificationTypeId !== NotificationEnum.Survey) {
        this.FillNotificationDetails(selectedNotificationItem);
      } else {
        this.FillSurveyDetails(selectedNotificationItem);
      }
    }
  }
  // Notification Item checkbox checked
  private CheckNotificationItems(
    val: NotificationItem,
    index: number,
    event: any,
  ) {
    this.UncheckSelectAllCheckbox();
    this.resetNotificationsItemsBackgroundColor();
    const notificationItemChecked = event.target.checked;
    if (notificationItemChecked) {
      this.selectedCheckboxCount += 1;
    } else {
      this.selectedCheckboxCount -= 1;
    }

    if (this.selectedCheckboxCount === 1) {
      if (notificationItemChecked) {
        this.SelectItem(val);
      } else {
        this.temporarySelectedItems = this.selectedItems;
        this.temporarySelectedItems = this.temporarySelectedItems.filter(
          (x) => x.Id !== val.Id,
        );
        this.SelectItem(this.temporarySelectedItems[0]);
      }
    }
  }

  private SelectItem(selectedNotifiicationItem: NotificationItem) {
    if (this.selectedItems.length === 1) {
      this.$emit('change-notification-item', {
        selectedItem: selectedNotifiicationItem,
        moveToAnotherTab: false,
      });
      this.selectednotificationItem = selectedNotifiicationItem;
    } else {
      this.updateSelectedItem(selectedNotifiicationItem);
    }
  }

  // #region UI control response

  // #region Sorting
  private CopyNotification(val: NotificationItem) {
    this.modalPopupVisible = true;
    this.showCloseButton = true;
    this.notificationCloneSource = undefined;
    this.notificationCloneSource = JSON.parse(JSON.stringify(val));
    if (val.NotificationTypeId === NotificationEnum.Survey) {
      this.showSectionModalPopup = this.NotificationSurveyCopy;
      this.modalPopupContent = this.$t('Dialogue.SurveyCopy').toString();
    } else {
      this.showSectionModalPopup = this.NotificationDialogueCopy;
      this.modalPopupContent = this.$t('Dialogue.Copy').toString();
    }
  }
  private UpdateSorting() {
    this.filteredNotificationItems.forEach((element, index) => {
      element.SortOrder = index;
    });
  }
  private SaveNotificationSortOrder() {
    this.UncheckSelectAllCheckbox();
    this.resetNotificationsItemsBackgroundColor();
    this.selectedItems = [];
    const notificationIds: number[] = [];
    this.filteredNotificationItems.forEach((element) => {
      notificationIds.push(element.Id);
    });
    this.showLoading = true;
    // call signalr
    const signalObject = this.SendNotificationRequest();
    this.$axios
      .post('/Notification/SetOrder', {
        idsInOrder: notificationIds.toString(),
      })
      .then(() => {
        this.UpdateSorting();
        this.$emit('update-sorting-notification-listing', {
          filteredNotificationItems: this.filteredNotificationItems,
          signalObject,
        });
        this.$root.$emit('set-notification-landing-page', true);
        this.showLoading = false;
      })
      .catch((error) => {
        window.console.log(error);
        this.showLoading = false;
      });
  }
  // #endregion Sorting

  // #region Model Popup

  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      if (this.showSectionModalPopup === this.WarningMessage) {
        this.resetNotificationsItemsBackgroundColor();
        this.updateSelectedItem(this.selectednotificationItem);
      } else if (this.showSectionModalPopup === this.Notification) {
        this.notificationItemDeletedOperation(this.selectedItemId);
        this.$emit(
          'notification-item-cancel-operation',
          NotificationItem.createEmpty(),
        );
        // empty selected item array if deleted
        this.selectedItems = [];
      } else if (this.showSectionModalPopup === this.NotificationSorting) {
        this.$root.$emit('set-notification-landing-page', true);
        this.$root.$emit('reload-notification-data');
        this.resetNotificationsItemsBackgroundColor();
        this.$root.$emit(
          'notification-item-cancel-operation',
          NotificationItem.createEmpty(),
        );
        // empty selected item array if deleted
        this.selectedItems = [];
      } else if (this.showSectionModalPopup === this.NotificationDialogueCopy) {
        this.CopyDialogue();
      } else if (this.showSectionModalPopup === this.NotificationSurveyCopy) {
        this.CopySurvey();
      }
    }
  }
  private CopyDialogue() {
    this.selectedItems = [];
    this.selectedCheckboxCount = 0;
    this.notificationCloneSource!.CopiedNotificationId = this.notificationCloneSource!.Id;
    this.notificationCloneSource!.Id = 0;
    this.notificationCloneSource!.PublishNow = 'false';
    this.notificationCloneSource!.IsPublished = false;
    this.notificationCloneSource!.ScheduleDate = undefined;

    if (this.notificationCloneSource!.NotificationOptions !== undefined && this.notificationCloneSource!.NotificationOptions !== null) {
      this.notificationCloneSource!.NotificationOptions.forEach((item) => {
        item.IsNewOption = true;
        item.NotificationCorrectOptionHash = '';
      });
    // this.UncheckAllCheckBoxesForcefully();
    }

    if (this.notificationCloneSource!.NotificationDimensions !== undefined && this.notificationCloneSource!.NotificationDimensions !== null) {
      this.notificationCloneSource!.NotificationDimensions.forEach((item) => {
        item.Id = -Math.abs(item.Id);
      });
    }
    this.$root.$emit('notification-item-copied', this.notificationCloneSource);
  }
  private CopySurvey() {
    this.selectedItems = [];
    this.selectedCheckboxCount = 0;
    // Copy existing dialogue properties to create new one
    const testSource: NotificationItem = JSON.parse(JSON.stringify(this.notificationCloneSource!));
    this.notificationCloneSource!.Id = 0;
    this.notificationCloneSource!.IsPublished = false;
    this.notificationCloneSource!.ScheduleDate = undefined;
    this.notificationCloneSource!.NotificationFeedbacks = 0;
    // remove sub items before copying to load them again in new edit pane (without results of they exist)
    this.notificationCloneSource!.QuestionnaireNotifications = [];
    this.$root.$emit('notification-item-copied', this.notificationCloneSource);
    this.isCopyOperation = true;
    setTimeout(() => {
      this.FillSurveyDetails(testSource);
    }, 100);
  }

  private SetModalPopupDefaultButtonText() {
    this.modalPopupShowCancelButton = true;
    this.modalPopupConfirmButtonText = this.$t('YesButton').toString();
    this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
  }
  // #endregion Model Popup

  // #region API calls

  // Load notification Details results

  private FillNotificationDetails(selectedNotificationItem: NotificationItem) {
    setTimeout(() => {
      this.$root.$emit('notificationDetailLoading', true);
    }, 10);
    // get details of Dialogue - api calling
    let url = '';
    url = '/Notification/DetailsNotification?Id=' + selectedNotificationItem.Id;
    this.$axios
      .post<NotificationDetailViewModel>(url)
      .then((response) => {
        if (response.data.Id > 0) {
          this.callrootlevelemits(response.data);
        } else {
          this.callrootlevelemits(response.data);
          this.$root.$emit('notificationDetailLoading', false);
        }
        this.selectednotificationItemValue = [];
        this.selectednotificationItemValue.push(selectedNotificationItem);
      })
      .catch(() => {
        this.$root.$emit('notificationDetailLoading', false);
      });
  }
  // set data to local Notification item after fetching details
  private callrootlevelemits(notificationDetailViewModel: NotificationDetailViewModel) {
    this.$root.$emit('update-notification-details', notificationDetailViewModel);
    this.$root.$emit('notification-feedback-removed',notificationDetailViewModel.Id,notificationDetailViewModel.NotificationFeedbacks);
    this.$root.$emit('update-notification-customecategory-item', notificationDetailViewModel.LinkableCustomCategories, notificationDetailViewModel.IsAnyLinkedItemWithNotification);
    this.$root.$emit('update-notification-program-item', notificationDetailViewModel.LinkablePrograms, notificationDetailViewModel.IsAnyLinkedItemWithNotification);
    // make loading false
    this.$root.$emit('notificationDetailLoading', false);
    this.$root.$emit('notification-show-overlay', false);
  }

  // Load Survey Details

  private FillSurveyDetails(selectedSurveyItem: NotificationItem) {
    setTimeout(() => {
      this.$root.$emit('notificationSurveyDetailLoading', true);
    }, 10);
    // get details of survey- api calling
    let url = '';
    url =
      '/Notification/DetailsQuestionnaireNotification?Id=' +
      selectedSurveyItem.Id;
    this.$axios
      .post<NotificationItem>(url)
      .then((response) => {
        if (response.data.Id > 0) {
          const responseData = response.data;
          selectedSurveyItem = responseData;
          // emit values to components
          this.callrootlevelSurveyEmits(selectedSurveyItem);
        } else {
          this.callrootlevelSurveyEmits(selectedSurveyItem);
        }
        this.selectednotificationItemValue = [];
        this.selectednotificationItemValue.push(selectedSurveyItem);
      })
      .catch(() => {
        this.$root.$emit('notificationSurveyDetailLoading', false);
      });
  }
  private callrootlevelSurveyEmits(selectedSurveyItem: NotificationItem) {
    // load details of Survey in  edit pane
    if (!this.isCopyOperation) {
      this.$root.$emit(
        'notification-survey-details',
        selectedSurveyItem,
      );
      this.$root.$emit('notification-feedback-removed',selectedSurveyItem.Id,selectedSurveyItem.NotificationFeedbacks);
    } else {
      // resetting all sub items before setting them in edit pane in Create New Case
      const subItemsList: NotificationItem = NotificationItem.createEmpty();
      subItemsList.QuestionnaireNotifications = [];
      selectedSurveyItem.QuestionnaireNotifications!.forEach((item) => {
        const notificationModel: NotificationItem = NotificationItem.createEmpty();
        notificationModel.Id = -Math.abs(item.Id);
        notificationModel.Title = item.Title;
        notificationModel.Description = item.Description;
        notificationModel.ImageFullUrl = item.ImageFullUrl;
        notificationModel.ImageThumbnailUrl = item.ImageThumbnailUrl;
        notificationModel.IsAllowNoResponse = item.IsAllowNoResponse;
        notificationModel.NotificationType = item.NotificationType;
        notificationModel.NotificationTypeId = item.NotificationTypeId;
        notificationModel.NotificationTypeDescription = item.NotificationTypeDescription;
        notificationModel.IsCreateNewItemInSurvey = true;
        notificationModel.NotificationFeedbacks = 0;
        notificationModel.ShowCorrectFeedbackToClients = item.ShowCorrectFeedbackToClients;
        notificationModel.SelectedChartTypeId = item.SelectedChartTypeId;
        notificationModel.NotificationOptions = [];
        if (item.NotificationOptions !== undefined && item.NotificationOptions !== null) {
          item.NotificationOptions.forEach((optionitem) => {
            optionitem.IsNewOption = true;
            optionitem.NotificationCorrectOptionHash = '';
            notificationModel.NotificationOptions!.push(optionitem);
          });
        }
        notificationModel.NotificationDimensions = [];

        if (item.NotificationDimensions !== undefined && item.NotificationDimensions !== null) {
          item.NotificationDimensions.forEach((dimensionitem) => {
            dimensionitem.Id = -Math.abs(dimensionitem.Id);
            notificationModel.NotificationDimensions!.push(dimensionitem);
          });
        }
        subItemsList.QuestionnaireNotifications!.push(notificationModel);
      });
      this.$root.$emit(
        'notification-survey-details',
        subItemsList,
      );
    }
    this.$root.$emit('notificationSurveyDetailLoading', false);
    this.$root.$emit('notification-show-overlay', false);
  }
  // #endregion API calls

  // #region Signal R

  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      this.$root.$emit('show-notification-popup', true);
      this.$root.$emit('show-notification-loading', true);
    }, 100);
    const signalR: NotificationSignalRModel = new NotificationSignalRModel(
      this.$root,
      this.$axios,
      this.$store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    signalObject = signalR.SortingNotificationItemSignalRModelRequest;
    const arr: number[] = [];
    this.filteredNotificationItems.forEach((notificationItem) => {
      arr.push(notificationItem.Id);
      signalObject.SelectedIds = arr;
    });

    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      user: signalR.GetUserName(
        signalObject,
        this.$t('UserName.You').toString(),
      ),
    }).toString();
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify(this.filteredNotificationItems);
    signalR.DisableNotificationItemsAndAddToNotificationPopup(signalObject);
    return signalObject;
  }

  // #endregion SignalR

  // #region Watch

  @Watch('notificationItems')
  private oncustomCategoryItemsPropertyChange(
    val: NotificationItem[],
  ) {
    this.UpdateFolderList(val);
    // keep the selected status "All /Published/ unpublished" after listing update
    this.OnStatusOptionChange(this.selectedStatusOption);
    /* Here this variable used to determine if user must be navigated to the "All" in folders or not
    when anything in listing updates the folder list also have to be updated and that cause user to navigate
    to "All" folders. This variable helps to remove that effect*/
    this.updateFilterandStatus = false;
    this.otherAdminUpdatingList = false;
  }
  // If folders updated that update the values in folders drop down
  @Watch('folders')
  private onFoldersPropertyChange(val: string[]) {
    this.BindFoldersDropdown(val);
  }
  /* selectedFolderName is used for implementation of new requirement where if User have folder selected and clicks
  on new Irtem create then by default that item must have Folder same as folder selected in listing for filteration*/
  @Watch('selectedFolderName')
  private onSelectedFOlderChange(val: string) {
    this.$root.$emit('selected-folder-in-listing', val);
  }
  @Watch('selectedItems')
  private onPropertyChange(
    val: NotificationItem[],
  ) {
    this.$emit('notification-items-selected', val);
    // emit property if diffirent notification item selected and scroll will go to top
    this.$root.$emit('new-notification-edit-item-pane', true);
  }

  @Watch('selectAllItems')
  private onPropertySelectAllItemsChange(val: boolean) {
    if (this.notificationItems) {
      if (val === true) {
        this.selectedItems = [];
        this.selectedCheckboxCount = 0;
        this.filteredNotificationItems.forEach((item) => {
          this.selectedItems.push(item);
          this.selectedCheckboxCount += 1;
        });
        if (this.selectedItems.length === 1) {
          if (
            this.selectedItems[0].NotificationTypeId !== NotificationEnum.Survey
          ) {
            this.FillNotificationDetails(this.selectedItems[0]);
          } else {
            this.FillSurveyDetails(this.selectedItems[0]);
          }
        }
      } else {
        if (this.emptySelectedItems) {
          this.selectedItems = [];
          this.selectedCheckboxCount = 0;
        }
      }
      this.emptySelectedItems = true;
    }
  }

  @Watch('editingNotificationItem')
  private ChangeEditingNotificationItem(
    val: NotificationItem,
  ) {
    let selectedNotification = this.selectedItems[0];
    if (this.selectedItems.length > 1) {
      selectedNotification = this.selectednotificationItem;
    }
    /* Compare object works only if values to be campaired are either both null or both are having values.
    if one of the value is null and other one has the value the CompareObject methos will fail. So to ensure that
    it works properly, we are using null check on the values that could possibly through such error*/
    if (val.ScheduleDate !== null && val.ScheduleDate !== undefined) {
      val.ScheduleDate = val.ScheduleDate !== null && moment(new Date(val.ScheduleDate.toString())).isValid() === true
        ? moment(val.ScheduleDate).toDate() : null!;
    }
    val.Folder = val.Folder === null ? '' : val.Folder;
    selectedNotification.Folder = selectedNotification.Folder === null ? '' : selectedNotification.Folder;
    val.Code = val.Code === null ? '' : val.Code;
    selectedNotification.Code = selectedNotification.Code === null ? '' : selectedNotification.Code;
    if (
      NotificationItem.prototype.CompareNotificationObjects({
        val1: val,
        val2: selectedNotification,
      }) && !this.IsSurveySubItemDirty
    ) {
      this.updateSelectedItem(this.selectednotificationItem);
    } else {
      this.showSectionModalPopup = this.WarningMessage;
      this.modalPopupVisible = true;
      this.modalPopupContent = this.GetMoveToAnotherItemMessage();
      this.SetModalPopupDefaultButtonText();
      this.showCloseButton = true;
    }
    this.IsSurveySubItemDirty = false;
  }

  // #endregion Watch
  private beforeDestroy() {
    this.$root.$off('notification-item-bulk-delete-operation');
    this.$root.$off('notification-item-bulk-folder-change-operation');
    this.$root.$off('selected-notification-item-notification-completed');
    this.$root.$off('selected-notification-item-survey-completed');
    this.$root.$off('notification-item-bulk-clear-responses-operation');
  }

  private IconToolTip(val: string) {
    return val;
  }
}
