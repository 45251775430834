/* eslint-disable max-len */
import { AxiosInstance } from 'axios';
import NotificationEnum, { NotificationTypeDescriptionEnumParse, NotificationTypeForSurveyEnumParse } from '@/enums/NotificationEnum';
export class NotificationType {
  public static createEmpty(): NotificationType {
    return new NotificationType();
  }
  constructor(
    public Id: number = 0,
    public Name: string = '',
    public IconUrl: string = '',
    public IconPadding: string= '',
    public Description: string = '',
  ) { }
}
export class NotificationTypes extends NotificationType {
  public arrNotificationOptions: NotificationType[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(rootObj: any, axios: AxiosInstance, $store: any, $t: any) {
    super();

    this.arrNotificationOptions.push({Id: 0, Name: NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(NotificationEnum.InformationMessage), IconUrl: 'fa fa-info-circle font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Send a push notification or post a message'});

    this.arrNotificationOptions.push({Id: 2, Name: NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(NotificationEnum.SingleChoiceQuestion), IconUrl: 'fa fa-bar-chart font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Polling where only one item can be selected'});

    this.arrNotificationOptions.push({Id: 3, Name: NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(NotificationEnum.MultipleChoiceQuestion), IconUrl: 'fa fa-bar-chart font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Polling where one item or more can be selected'});

    this.arrNotificationOptions.push({Id: 1, Name: NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(NotificationEnum.SingleRespponseTextFeedback), IconUrl: 'fa fa-comments-o font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Submit only one text-based response'});

    this.arrNotificationOptions.push({Id: 4, Name: NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(NotificationEnum.MultipleRespponseTextFeedback), IconUrl: 'fa fa-comments-o font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Submit multiple text-based responses'});

    this.arrNotificationOptions.push({Id: 6, Name: NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(NotificationEnum.PickCorrect), IconUrl: 'fa fa-check font-awesome-icon', IconPadding: 'padding:14px;', Description: 'Quiz your audience and see who answers correctly'});

    this.arrNotificationOptions.push({Id: 7, Name: NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(NotificationEnum.RankOptionItems), IconUrl: 'fa fa-sort-amount-desc font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Sort rank the items from most to least important'});

    this.arrNotificationOptions.push({Id: 8, Name: NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(NotificationEnum.ScaleStatements), IconUrl: 'fa fa-sliders font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Let audience leave feedback on scale rating'});
  }
}

export class NotificationTypesforSurvey extends NotificationType {
  public arrNotificationOptions: NotificationType[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(rootObj: any, axios: AxiosInstance, $store: any, $t: any) {
    super();

    this.arrNotificationOptions.push({Id: 0, Name: NotificationTypeForSurveyEnumParse.GetNotificationTypeEnum(NotificationEnum.InformationMessage), IconUrl: 'fa fa-info-circle font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Send a push notification or post a message'});

    this.arrNotificationOptions.push({Id: 2, Name: NotificationTypeForSurveyEnumParse.GetNotificationTypeEnum(NotificationEnum.SingleChoiceQuestion), IconUrl: 'fa fa-bar-chart font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Submit only one text-basesd response'});

    this.arrNotificationOptions.push({Id: 3, Name: NotificationTypeForSurveyEnumParse.GetNotificationTypeEnum(NotificationEnum.MultipleChoiceQuestion), IconUrl: 'fa fa-bar-chart font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Submit multiple text-based responses'});

    this.arrNotificationOptions.push({Id: 1, Name: NotificationTypeForSurveyEnumParse.GetNotificationTypeEnum(NotificationEnum.SingleRespponseTextFeedback), IconUrl: 'fa fa-comments-o font-awesome-icon', IconPadding: 'padding:17px;', Description: 'This is single response text feedback'});

    this.arrNotificationOptions.push({Id: 6, Name: NotificationTypeForSurveyEnumParse.GetNotificationTypeEnum(NotificationEnum.PickCorrect), IconUrl: 'fa fa-check font-awesome-icon', IconPadding: 'padding:17px;', Description: 'This is multiple response text feedback'});

    this.arrNotificationOptions.push({Id: 7, Name: NotificationTypeForSurveyEnumParse.GetNotificationTypeEnum(NotificationEnum.RankOptionItems), IconUrl: 'fa fa-sort-amount-desc font-awesome-icon', IconPadding: 'padding:17px;', Description: 'This is rank option feedback'});

    this.arrNotificationOptions.push({Id: 8, Name: NotificationTypeForSurveyEnumParse.GetNotificationTypeEnum(NotificationEnum.ScaleStatements), IconUrl: 'fa fa-sliders font-awesome-icon', IconPadding: 'padding:17px;', Description: 'This is scale statement type feedback'});
  }
}


