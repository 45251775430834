import { Vue } from 'vue-property-decorator';
export default class HighchartHelper extends Vue{
  public static ChartBackgroundColor = 'rgba(0,0,0,0)';
  public static BarBorderRadius = '15%';
  public static BarDataLabelsFormat = '{y}%';
  public static ScalingDataLabelsFormat = '{y}';
  public static BorderWidth = 0;
  public static PointPadding = 0;
  public static ShowInLegend = false;
  public static ColorByPoint = true;
  public static AreLegendsEnabled = false;
  public static ExportingEnabled = false;
  public static IsTooltipEnabled = false;
  public static AreCreditsEnabled = false;
  public static GridLineWidth = 0;
  public static FontColor = 'black';
  public static FontFamily = 'Open Sans';
  public static FontWeight = 600;
  public static Colors = ['#295E48','#86198F','#E49736','#E45F5F','#1E3A8A','#6396DD'];
  public static LineHeight = 1.2;
  public static LineColor = 'Black';
  public static BarChart = 'bar';
  public static RankingChart = 'rank';
  public static ScalingChart = 'scalingbar';
  public static PieChart = 'pie';
  public static WordCloud = 'wordcloud';
  public static TickDistance = 10;
  public static ChartMargin = 0;
  public static LegendMargin = 2;
  public static TickInterval = 1;
  public static lineWidth = 0;
  public static SpiderFillOpacity = 0.5;
  public static tooltipBackgroundColor = '#FFF';
  public static WordCloudMaxFontSize = 20;
  public static WordCloudMinFontSize = 5;
  public static WordCloudRotationFrom = 0;
  public static WordCloudRotationTo = 0;
  public static WordCloudRotationOrientation = 1;
  public static Exporting  =  {
    buttons: {
      contextButton: {
        menuItems: [{
          text: 'Download JPEG image',
          onclick(this: any) {
            this.update({
              chart: {
                backgroundColor: '#fafafa',  // Set background color for JPEG
              },
            });
            this.exportChart({
              type: 'image/jpeg',
            });
            this.update({
              chart: {
                backgroundColor: null,  // Reset background color
              },
            });
          },
        }, {
          text: 'Download PNG image',
          onclick(this: any) {
            this.update({
              chart: {
                backgroundColor: null,  // Set transparent background for PNG
              },
            });
            this.exportChart({
              type: 'image/png',
            });
          },
        }],
      },
    },
  };
}
