






















































































































import { Component, Vue } from 'vue-property-decorator';
import { NotificationType, NotificationTypes } from '@/entities/Notification/NotificationTypes';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import NotificationEnum, { NotificationTypeDescriptionEnumParse, NotificationTypeEnumParse } from '@/enums/NotificationEnum';

@Component
export default class NotificationAddItemPane extends Vue {
  private EnableRankingDialogue = false;
  private EnableScalingDialogue = false;
  private notificationTypes: NotificationTypes = new NotificationTypes(
    this.$root,
    this.$axios,
    this.$store,
    this.$t,
  );
  private newNotificationItem: NotificationItem | null = null;
  private newNotificationItemDropDown: NotificationItem | null = null;
  private evenIndex(index: number): boolean {
    if (index % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }
  private mounted() {
    this.GetRequestInfoFromStore();
    /* If Ranking type question is disabled at App setting level then we will remove the ranking type
  dialogue from notificationTypes array thus preventing the ranking type option to ever render on the
  create panel*/
    if (!this.EnableRankingDialogue) {
      const index = this.notificationTypes.arrNotificationOptions.findIndex(
        (x: NotificationType) => x.Id === NotificationEnum.RankOptionItems);
      if (index > -1) {
        this.notificationTypes.arrNotificationOptions.splice(index, 1);
      }
    }
    /* If Scaling type question is disabled at App setting level then we will remove the scaling type
  dialogue from notificationTypes array thus preventing the scaling type option to ever render on the
  create panel*/
    if (!this.EnableScalingDialogue) {
      const index = this.notificationTypes.arrNotificationOptions.findIndex(
        (x: NotificationType) => x.Id === NotificationEnum.ScaleStatements);
      if (index > -1) {
        this.notificationTypes.arrNotificationOptions.splice(index, 1);
      }
    }
    this.$root.$on(
      'notification-create-dialogue-from-dropdown',
      (Id: number) => {
        this.CreateNewNotificationItemDropDown(Id);
      });

    // If user adds Title and Image while creating, they must not be changed as in Old Admin
    this.$root.$on(
      'notification-create-dialogue-image-uploaded',
      (ThumbnailUrl: string, ImgFullUrl: string) => {
        this.SetImageUrls(ThumbnailUrl, ImgFullUrl);
      });
    this.$root.$on(
      'notification-title-preserve', (title: string) => {
        this.newNotificationItemDropDown!.Title = title;
      });
  }
  private created() {
    const newItemDropDown = NotificationItem.createEmpty();
    this.newNotificationItemDropDown = newItemDropDown;
  }

  // New Item Object created when user clicks on any one item from the page (appears after Create new click button)
  // where all 8 types of dialogues are listed
  private CreateNewNotificationItemTypes(typeId: number) {
    this.newNotificationItem = null;
    const newItem = NotificationItem.createEmpty();
    this.newNotificationItem = newItem;
    // do not reratain title and image if dialogue created from Create item types listing page
    this.newNotificationItemDropDown!.Title = '';
    this.newNotificationItemDropDown!.ImageFullUrl = '';
    this.newNotificationItemDropDown!.ImageThumbnailUrl = '';
    this.newNotificationItem.NotificationTypeId = typeId;
    this.newNotificationItem.NotificationType = NotificationTypeEnumParse.GetNotificationTypeEnum(typeId);

    this.newNotificationItem.NotificationTypeDescription = NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(typeId);
    this.$root.$emit('notification-create-new-operation', this.newNotificationItem, false);
  }

  // Changing Object typeid, type description when user changees dialogue type from drop down while creating
  private CreateNewNotificationItemDropDown(typeId: number) {
    this.newNotificationItemDropDown!.NotificationTypeId = typeId;
    this.newNotificationItemDropDown!.NotificationType = NotificationTypeEnumParse.GetNotificationTypeEnum(typeId);

    this.newNotificationItemDropDown!.NotificationTypeDescription = NotificationTypeDescriptionEnumParse.GetNotificationTypeDescriptionEnum(typeId);
    this.$root.$emit('notification-create-new-operation', this.newNotificationItemDropDown, true);
  }
  private SetImageUrls(ImageThumbnailUrl: string, ImgFullUrl: string) {
    /* saving image urls if image uploaded under a dialogue, then if user switches to different dialogue type,
   the image will remain same that once uploaded (same as old admin)*/
    this.newNotificationItemDropDown!.ImageFullUrl = ImgFullUrl;
    this.newNotificationItemDropDown!.ImageThumbnailUrl = ImageThumbnailUrl;
  }
  private ShowNotificationOption(item: NotificationType): boolean {
    if (item.Id === 7 && this.EnableRankingDialogue === false) {
      return false ;
    } else if (item.Id === 8 && this.EnableScalingDialogue === false) {
      return false ;
    } else {
      return true;
    }
  }
  private GetRequestInfoFromStore() {
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    this.EnableRankingDialogue = Boolean(reqInfo.EnableRankingDialogue);
    this.EnableScalingDialogue = Boolean(reqInfo.EnableScalingDialogue);
  }
  private Close() {
    this.$emit('notification-create-new-pane-close');
  }
}
