



















































import { Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { NotificationSegmentQuestion } from '@/entities/Notification/NotificationSegmentQuestion';

@Component
export default class NotificationExportPane extends Vue {
// readonly properties
  private readonly Standard: string = 'Standard';
  private readonly Custom = 'Custom';

  @Prop() private participantSegmentsExportPane?: NotificationSegmentQuestion[];
  @Prop() private isScrollable?: boolean;

  private segmentQuestions?: NotificationSegmentQuestion | null = null;
  private isStandardSelected?: boolean = false;
  private isCustomSelected?: boolean = false;
  private selectedItems: string[] = [];
  private localparticipantSegments?: NotificationSegmentQuestion[];
  private selectedSegmentItems?: NotificationSegmentQuestion[];

  private mounted() {
    this.localparticipantSegments = this.participantSegmentsExportPane;
  }

  private checkedCustom() {
    this.isCustomSelected = true;
    this.$emit('export-type-selected', {
      selectedtype: this.Custom,
      segmentoptions: this.selectedSegmentItems,
    });
  }
  private IsScrollVisible(value: boolean) {
    if (value) {
      return 'scroll-segments';
    }
  }

  private checkedStandard() {
    this.isCustomSelected = false;
    this.selectedSegmentItems = [];
    this.$emit('export-type-selected',  {
      selectedtype: this.Standard,
      segmentoptions: null,
    });
  }
  @Watch('selectedItems')
  private onPropertyChange(
    val: NotificationSegmentQuestion[],
  ) {
    const copyArr = Array.from(val);
    this.selectedSegmentItems = copyArr;
    this.checkedCustom();
  }
}
