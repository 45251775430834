



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import NotificationEnum from '@/enums/NotificationEnum';
@Component({
  components: {
    ModalPopup,
    VueModalPopUp,
  },
})
export default class NotificationResponseCounterAndReminder extends Vue {
  @Prop() private selectedNotificationItem!: NotificationItem;
  private localNotificationItem: NotificationItem = NotificationItem.createEmpty();
  private totalUsersFeedbacksCount = 0;
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private modalPopupShowCancelButton = true;
  private showVueModalPopUp = false;
  private sendReminderPopUpContent: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();
  private mounted() {
    this.localNotificationItem = Object.assign({},this.selectedNotificationItem);
    this.SetUserFeedbackCount();
    this.SetSendReminderPopUpContent();
  }
  private SetSendReminderPopUpContent() {
    this.sendReminderPopUpContent = new VueModalPopUpViewModel(
      this.$t('Dialogue.SendReminder').toString(),
      this.$t('Dialogue.SendReminderDescription',{remindersCount: this.localNotificationItem.CountOfRemainingRemindersToSend, reminderString: this.GetReminderString()}).toString(),
      false,
      '',
      this.$t('Button.DoNotSend').toString(),
      this.$t('Button.Send').toString(),
    );
  }
  // this helps in setting and updating the user feedback count which enables or disables the clear all responses options
  private SetUserFeedbackCount() {
    if(this.localNotificationItem.UserFeedback !==undefined) {
      this.totalUsersFeedbacksCount = this.localNotificationItem.UserFeedback.length;
    }
  }
  private IsSendReminderLinkDisabled() {
    return (this.localNotificationItem.IsPublished && this.localNotificationItem.RecipientCount !==0 && this.localNotificationItem.CountOfRemainingRemindersToSend !== 0 && this.localNotificationItem.NotificationFeedbacks !== this.localNotificationItem.RecipientCount);
  }
  private GetText() {
    return this.$t('Dialogue.HighCharts.ResposeSummreryText', {responseCount : this.localNotificationItem.NotificationFeedbacks, participantCount: this.localNotificationItem.RecipientCount});
  }
  private GetTotalCountOfReminder() {
    return this.$t('Dialogue.RemindersTotalCount',{remindersCount: this.localNotificationItem.CountOfRemainingRemindersToSend, reminderString: this.GetReminderString()});
  }
  private GetReminderString() {
    return this.localNotificationItem.CountOfRemainingRemindersToSend > 1 ? 's' : '';
  }
  private IsReminderLinkVisible() {
    if(this.localNotificationItem.QuestionnaireNotifications !== null && this.localNotificationItem.QuestionnaireNotifications !== undefined) {
      let countOfInformationDialogueItem = 0;
      this.localNotificationItem.QuestionnaireNotifications.forEach((item)=> {
        if(item.NotificationTypeId === NotificationEnum.InformationMessage) {
          countOfInformationDialogueItem++;
        }
      });
      return (this.localNotificationItem.QuestionnaireNotifications.length === countOfInformationDialogueItem);
    }
  }
  private ShowReminderPopUp() {
    if(this.IsSendReminderLinkDisabled()) {
      this.SetSendReminderPopUpContent();
      this.showVueModalPopUp = true;
    }
  }
  private CloseVueModelPopup() {
    this.showVueModalPopUp = false;
  }
  private SendReminderToParticipants() {
    // Api call for updating reminders;
    this.$axios.post<any>('Notification/SendReminderToParticipants',{
      notificationId: this.localNotificationItem.Id,
    }).then((response)=> {
      if (response.data.Success === true) {
        this.localNotificationItem.CountOfRemainingRemindersToSend = response.data.remainingReminders;
        this.$root.$emit('notification-show-overlay', true);
        this.$root.$emit('update-reminder-count-of-notification-item',this.localNotificationItem.Id,response.data.remainingReminders);
      }
    })
      .catch(()=> {
        // console log to print error
      });
    this.showVueModalPopUp = false;
  }
  private ShowToolTip() {
    if(!this.localNotificationItem.IsPublished) {
      return this.$t('Dialogue.SendReminderUnpublishTooltip');
    }
    if(this.localNotificationItem.RecipientCount === 0) {
      return this.$t('Dialogue.SendReminderZeroParticipantTooltip');
    }
    if(this.localNotificationItem.NotificationFeedbacks === this.localNotificationItem.RecipientCount) {
      return this.$t('Dialogue.SendReminderAllParticipantsRespondedTooltip');
    }
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      if(this.localNotificationItem.IsSurvey) {
        this.ClearAllSurveyItemsResponses();
      } else {
        this.ClearAllDialogueItemResponses();
      }
    }
  }
  private IsClearAllResponsesDisabled() {
    return (this.totalUsersFeedbacksCount === 0 && this.localNotificationItem.NotificationFeedbacks === 0);
  }
  private ClearAllSurveyItemsResponses() {
    this.$axios.post<any>('Notification/DeleteAllSurveyReposnes',{
      surveyId: this.localNotificationItem.Id,
    }).then((response)=> {
      if (response.data.Success === true) {
        this.localNotificationItem.NotificationFeedbacks = 0;
        this.$root.$emit('notification-show-overlay', true);
        this.$root.$emit('reload-sub-notification-detail',this.localNotificationItem);
      }
    })
      .catch(()=> {
        // console log to print error
      });
  }
  private ClearAllDialogueItemResponses() {
    this.$axios
      .post<any>('/Notification/DeleteAllReposne', {
      notificationId: this.localNotificationItem.Id,
    })
      .then((response) => {
        if (response.data.Success === true) {
          this.localNotificationItem.UserFeedback = [];
          this.$root.$emit('notification-show-overlay', true);
          if (!this.localNotificationItem.IsSurvey) {
            this.$root.$emit('reload-notification-detail');
          } else {
            this.$root.$emit('reload-sub-notification-detail', this.localNotificationItem);
          }
          this.$root.$emit('notification-feedback-removed', this.localNotificationItem.Id, 0);
        }
      })
      .catch(() => {
        // Log error if required
      });
  }
  private ClearAllResponseClicked() {
    if(!this.IsClearAllResponsesDisabled()) {
      this.showCloseButton = true;
      this.modalPopupContent = this.$t('Dialogue.ClearAllResponses').toString();
      this.modalPopupVisible = true;
    }
  }
  @Watch('selectedNotificationItem',{deep: true})
  private CheckChangeInselectedNotificationItem(newVal: NotificationItem) {
    this.localNotificationItem = newVal;
    this.SetUserFeedbackCount();
  }
}
