



























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Datepicker from '@/components/Common/Datepicker.vue';
import { StoreHelper } from '@/store/StoreHelper';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';

@Component({
  components: {
    Datepicker,
    ValidationSummary,
  },
})
export default class NotificationSchedule extends Vue {
  @Prop() private newProgramStartDate?: Date;
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private timeZone = '';
  private noTimeZoneText: string = this.$t('Dialogue.NoTimeZoneText').toString();
  private IsPublishAutomaticSelected = false;
  private isIEbrowser = false;
  private minDateTime = new Date().toISOString();
  private publishLaterDate = new Date();
  private isValidationSummaryVisible = false;
  private validationErrorFields: string[] = [];
  private mounted() {
    this.minDateTime = new Date(this.GetProgramStartDate()).toISOString();
    this.timeZone = this.storeHelper.GetActiveEventIanaTimeZoneFromStore();

    if (navigator.appName === 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
      this.isIEbrowser = true;
    }
  }
  private ClosePublishLater() {
    // emit for closing Publish later pane on click of close button
    this.$emit('selectClose', false);
  }
  private checkedManually() {
    // handles radio button selection of publish manually to create Unpublished dialogue
    this.IsPublishAutomaticSelected = !this.IsPublishAutomaticSelected;
    this.$root.$emit('notification-set-schedule-date', null);

  }
  private checkedAutomatic() {
  /* handles radio button selection of publish later, This makes datepicker
  for scheduling visible, and lets user create scheduled dioalogue */
    this.IsPublishAutomaticSelected = !this.IsPublishAutomaticSelected;
  }
  private GetProgramStartDate(): Date {
    // Get time zone from a store
    const timeZones: string = this.storeHelper.GetActiveEventIanaTimeZoneFromStore();
    let dateString = '';
    // check if timezone is existing in the event
    if (timeZones !== '' && timeZones !== null && !this.isIEbrowser) {
      dateString = new Date().toLocaleString('en-Us', {timeZone: timeZones});
    } else {
      dateString = new Date().toLocaleString();
    }
    const startDate = new Date(dateString);
    const date: Date = new Date(
      Date.UTC(
        startDate.getUTCFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startDate.getHours(),
        startDate.getMinutes(),
      ),
    );
    return date;
  }
  private CheckTimeFormat(): boolean {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    return browsersettings.isFullDayTimeFormat;
  }
  private SetScheduleDateValue(date: Date) {
    this.publishLaterDate = date;
    // set values in Parent Edit pane for selected schedule date.
    if (this.IsPublishAutomaticSelected && this.ValidatePublishLaterDate()) {
      this.$root.$emit('notification-set-schedule-date', date);
    }
  }
  private PublishLater() {
    // Emit for Saving dailogue. This emit will call methods of hitting API to Save dialogue
    this.validationErrorFields = [];
    if(this.ValidatePublishLaterDate() || !this.IsPublishAutomaticSelected) {
      this.isValidationSummaryVisible = false;
      this.$emit('publishLater');
    } else {
      this.validationErrorFields.push(
        this.$t('Notification.PreviousDateErrorMessage').toString(),
      );
      this.isValidationSummaryVisible = true;
    }
  }
  private OpenDatePicker() {
    const element: HTMLBodyElement = document.querySelector('#scheduledatetimepicker > div input' ) as HTMLBodyElement;
    element.click();
  }
  private CloseValidationSummary(visible: boolean) {
    this.isValidationSummaryVisible = visible;
  }
  private ValidatePublishLaterDate() {
    // return this.publishLaterDate.toString() > this.minDateTime;
    // code for DEV-7097
    return new Date(this.publishLaterDate).toISOString() > this.minDateTime;
  }
}
