/* eslint-disable max-len */
import { BaseSignalRModel } from '../BaseSignalRModel';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import EmitEnum from '@/enums/EmitEnums';
import { SignalRModelRequest } from '../SignalRModelRequest';
import { Vue } from 'vue-property-decorator';
import { AxiosInstance } from 'axios';
import { NotificationItem, NotificationItemSignalR } from '@/entities/Notification/NotificationItem';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';

const NotificationSignalRModelRequestIds = {
  SingleCreate: 1,
  SingleEdit: 2,
  SingleDelete: 3,
  SingleDetailEdit: 4,
};

export class NotificationSurveySignalRModel extends BaseSignalRModel {
  // Get SignalRModelRequest for adding a single notification item
  get SingleCreateNotificationSurveyItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationSignalRModelRequestIds.SingleCreate);
  }
  get SingleUpdateNotificationSurveyItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationSignalRModelRequestIds.SingleEdit);
  }
  // Get SignalRModelRequest for Deleting a single information item
  get SingleDeleteNotificationSurveyItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(NotificationSignalRModelRequestIds.SingleDelete);
  }
  readonly batchOperationURL = '/BulkOperations/PerformBatchOperation';
  readonly postToClientsSignalRURL = '/BulkOperations/PostToClientsSignalR';
  constructor(public rootObj: any, public axios: AxiosInstance, public $store: any, public $t: any) {
    super(rootObj, axios, $store);
    const reqInfo: AppRequestInfo = this.GetRequestInfoFromStore();
    const loggedInUserFullName = reqInfo.FirstName + ' ' + reqInfo.LastName;
    const signalRAPIDialogueURL = Vue.prototype.$SignalRBaseURL + 'api/SignalRDialogue/PushDialogueSignalRChanges';

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationSignalRModelRequestIds.SingleDelete, ModuleEnum.Survey, SubModuleEnum.SingleDelete,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, NotificationSurveySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIDialogueURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteSingleSurveyItem', 'Notifications.DeletedSingleSurveyItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleSurvey'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationSignalRModelRequestIds.SingleCreate, ModuleEnum.Survey, SubModuleEnum.SingleCreate,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, NotificationSurveySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIDialogueURL, [], 'Notifications.SaveOperation', '', 'Notifications.SavingNotificationSurveyItem', 'Notifications.SavedSurveyItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleSurvey'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(NotificationSignalRModelRequestIds.SingleEdit, ModuleEnum.Survey, SubModuleEnum.SingleEdit,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, NotificationSurveySignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIDialogueURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingSurveyItem', 'Notifications.UpdatedSurveyItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleSurvey'));
  }
  // Perform Survey Single Operations
  public PerformNotificationSingleOperation(signalRModelRequest: SignalRModelRequest) {
    // disable program items
    if (signalRModelRequest.SelectedIds.length > 0) {
      if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate) {
        this.DisableNotificationItems(signalRModelRequest);
      }
    }
    this.SendSignalRModelRequestToHub(signalRModelRequest, this.batchOperationURL);
  }
  // Get Survey Item to send to SignalR as JSON data
  public GetNotificationItemToSendToSignalR(notificationItem: NotificationItem): string {

    const item: NotificationItemSignalR = NotificationItemSignalR.GetNotificationSignalRItem(notificationItem);
    return JSON.stringify(item);
  }
  // Method that fires when custom category SignalR response model is received from Hub
  public NotificationSignalRModelResponseReceived(signalRModelRequest: SignalRModelRequest) {
    this.SignalRModelResponseReceived(signalRModelRequest);
    // enable custom category items

    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate &&
            signalRModelRequest.SubModule !== SubModuleEnum.SingleDelete &&
            signalRModelRequest.SubModule !== SubModuleEnum.Sorting) {
      if (this.IsLoggedInUserIsMe(signalRModelRequest)) {
        this.EnableNotificationItems(signalRModelRequest);
      }
    }
  }
  public GetSelectedNotificationIdsToSendInJson(selectedItems: NotificationItem[]): number[] {

    const selectedNotificationIds: number[] = [];
    selectedItems.forEach((element) => {
      selectedNotificationIds.push(element.Id);
    });
    return selectedNotificationIds;
  }
  // Disable custom category Items and add to Notification Popup that an operation has started

  public DisableNotificationSurveyItemAndAddToNotificationPopup(signalRModelRequest: SignalRModelRequest) {
    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate &&
            signalRModelRequest.SubModule !== SubModuleEnum.SingleDetailEdit &&
            signalRModelRequest.SubModule !== SubModuleEnum.Sorting) {
      this.DisableNotificationItems(signalRModelRequest);
    }
    this.EmitAddNewNotifications(signalRModelRequest);
  }
  // Disable Selected Notification Items based on notification Ids
  private DisableNotificationItems(signalRModelRequest: SignalRModelRequest) {
    this.rootObj.$emit(EmitEnum.disableNotificationItems, signalRModelRequest.SelectedIds);
  }
  // Enable Selected Notification Items based on notification Ids
  private EnableNotificationItems(signalRModelRequest: SignalRModelRequest) {
    this.rootObj.$emit(EmitEnum.enableNotificationItems, signalRModelRequest.SelectedIds);
  }
}
