



































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NotificationEnum from '@/enums/NotificationEnum';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { NotificationFilter } from '@/entities/Notification/NotificationFilter';
import NotificationSingleChoiceQuestionEditPane from '@/components/Notification/EditNotification/NotificationSingleChoiceQuestionEditPane.vue';
import NotificationInformationMessageEditPane from '@/components/Notification/EditNotification/NotificationInformationMessageEditPane.vue';
import NotificationMultipleChoiceQuestionEditPane from '@/components/Notification/EditNotification/NotificationMultipleChoiceQuestionEditPane.vue';
import NotificationSingleResponseTextFeedbackEditPane from '@/components/Notification/EditNotification/NotificationSingleResponseTextFeedbackEditPane.vue';
import NotificationMultiResponseTextFeedbackEditPane from '@/components/Notification/EditNotification/NotificationMultiResponseTextFeedbackEditPane.vue';
import NotificationPickCorrectAnswerEditPane from '@/components/Notification/EditNotification/NotificationPickCorrectAnswerEditPane.vue';
import NotificationRankOptionItemEditPane from '@/components/Notification/EditNotification/NotificationRankOptionItemEditPane.vue';
import NotificationScaleStatementEditPane from '@/components/Notification/EditNotification/NotificationScaleStatementEditPane.vue';
import NotificationResultsDetails from '@/components/Notification/NotificationResults/NotificationResultsDetails.vue';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { ProgramItem } from '@/entities/Program/ProgramItem';
@Component({
  components: {
    NotificationSingleChoiceQuestionEditPane,
    NotificationInformationMessageEditPane,
    NotificationMultipleChoiceQuestionEditPane,
    NotificationSingleResponseTextFeedbackEditPane,
    NotificationMultiResponseTextFeedbackEditPane,
    NotificationPickCorrectAnswerEditPane,
    NotificationRankOptionItemEditPane,
    NotificationScaleStatementEditPane,
    NotificationResultsDetails,
  },
})
export default class NotificationEditItemPane extends Vue {
// Properties
  @Prop() private selectedNotificationItem?: NotificationItem;
  @Prop() private folders?: string[];
  @Prop() private filters?: NotificationFilter[];
  @Prop() private availableCustomCategoryFolders?: CustomCategoryFolder[];
  @Prop() private availableCustomCategoryItems?: CustomCategoryItem[];
  @Prop() private availableProgramItems?: ProgramItem[];

  // local variables
  private isMobileView = false;
  private notificationTypeId = -1;
  private localNotificationItem: NotificationItem | null = null;

  public get notificationEnum(): typeof NotificationEnum {
    return NotificationEnum;
  }
  private mounted() {
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.localNotificationItem = Object.assign({}, this.selectedNotificationItem);

    this.notificationTypeId = this.localNotificationItem.NotificationTypeId;

    this.$root.$on('notification-edited', (notificationItem: NotificationItem) => {
      this.localNotificationItem = notificationItem;
    });
    this.$root.$on('notification-feedback-removed', (Id: number, leftResponses: number) => {
      this.localNotificationItem!.NotificationFeedbacks = leftResponses;
      this.$root.$emit('new-notification-edit-item-pane');
    });
  }
  @Watch('selectedNotificationItem')
  private onItemPropertyChange(val: NotificationItem) {
    this.localNotificationItem = val;
    this.notificationTypeId = val.NotificationTypeId;
  }
  @Watch('localNotificationItem')
  private onlocalItemPropertyChange(newval: NotificationItem) {
    this.$root.$emit('notification-edited-to-parent', newval);
  }
}
