




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { NotificationSignalRModel } from '@/signalRRequest/Notification/NotificationSignalRModel';
import NotificationExportPane from '@/components/Notification/NotificationExport/NotificationExportPane.vue';
import { NotificationSegmentQuestion } from '@/entities/Notification/NotificationSegmentQuestion';


@Component({
  components: {
    NotificationExportPane,
  },
})

export default class NotificationBatchExport extends Vue {
  @Prop() private selectedItems?: NotificationItem[];
  @Prop() private participantSegments?: NotificationSegmentQuestion[];
  @Prop() private selectedBulkOperationModel?: SignalRModelRequest;

  private selectedExportType = '';
  private selectedparticipantSegments?: NotificationSegmentQuestion[];

  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private localparticipantSegments?: NotificationSegmentQuestion[] = [];
  private signalR: NotificationSignalRModel = new NotificationSignalRModel(
    this.$root,
    this.$axios,
    this.$store,
    this.$t,
  );
  private mounted() {
    this.localSelectedBulkOperationModel = this.selectedBulkOperationModel!;
    this.localparticipantSegments = this.participantSegments;
  }
  private CloseExport() {
    this.$emit('selectBack');
  }
  private onExportOptionsSelected(object: any) {
    // clearing previous selected type and participant Segments
    this.selectedExportType = '';
    this.selectedparticipantSegments = [];
    // setting selected type and participant Segments
    this.selectedExportType = object.selectedtype;
    this.selectedparticipantSegments = object.segmentoptions;
  }
  private Export() {
    /* sending selected selected items/ export type / segment options
    to Export API call in Notification.Vue */
    this.$root.$emit('notification-export-item-selected', {
      selectedtype: this.selectedExportType,
      segmentoptions: this.selectedparticipantSegments,
      notificationOptions: this.selectedItems,
      exportFileName: 'Notification_Feedbacks.xlsx',
    });
  }
}
