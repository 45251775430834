
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component

export default class SendPushOnPublish extends Vue {
  @Prop() private IsSentPublish?: boolean;
  @Prop() private IsPublished?: boolean;

  private localIsSentPublish = false;
  private localIsPublished = false;

  private mounted() {
    this.localIsSentPublish = this.IsSentPublish!;
    this.localIsPublished = this.IsPublished!;
  }
  private change() {
    // any property change
    this.$emit('setIsSentPublishProperties', this.localIsSentPublish);
    this.$root.$emit('setIsSentPublishProperties', this.localIsSentPublish);
  }

  @Watch('IsPublished')
  private onIsPublishedChanged(){
    this.localIsPublished = this.IsPublished!;
  }
}
